import { useListAllExpenseGroupQuery } from '@/app/services/expenseGroupApi';
import { useListAllCategoriesQuery } from '@/app/services/categoryApi';

import NotRegisteredEvents from '@/components/events/NotRegisteredEvents';
import RegisteredEvents from '@/components/events/RegisteredEvents';
import PeriodDetails from '@/components/events/PeriodDetails';
import { useExpenseQuery } from '@/app/services/expenseApi';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import { Box } from '@mui/material';

enum EventTables {
  'NOT_REGISTERED_EVENTS',
  'REGISTERED_EVENTS',
}

function Events() {
  const { t } = useTranslation('events');
  const [selectedTable, setSelectedTable] = useState(EventTables.NOT_REGISTERED_EVENTS);

  const { data: groups } = useListAllExpenseGroupQuery();

  const { data: categories } = useListAllCategoriesQuery();

  const { data } = useExpenseQuery({ bringOnlyNew: true, page: 0, size: 10, timestamp: 0 });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
      <PeriodDetails />
      <div style={{ marginTop: '32px', fontSize: '18px', fontWeight: 'bold' }}>{t('Eventos')}</div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
        <Box
          onClick={() => setSelectedTable(EventTables.NOT_REGISTERED_EVENTS)}
          sx={{
            display: 'flex',
            fontSize: '13px',
            fontWeight: `${selectedTable === EventTables.NOT_REGISTERED_EVENTS ? 'bold' : 'normal'}`,
            cursor: 'pointer',
            padding: '8px 54px 8px 14px',
            borderBottom: `2px solid ${selectedTable === EventTables.NOT_REGISTERED_EVENTS ? '#ff9900' : '#bababa'}`,
          }}
        >
          {t('Pendentes')}
        </Box>
        <Badge
          sx={{
            '& .MuiBadge-badge': {
              right: '30px',
              top: '18px',
            },
          }}
          badgeContent={data?.totalElements || 0}
          color="warning"
        />
        <Box
          onClick={() => setSelectedTable(EventTables.REGISTERED_EVENTS)}
          sx={{
            display: 'flex',
            fontSize: '13px',
            cursor: 'pointer',
            padding: '8px 10px',
            fontWeight: `${selectedTable === EventTables.REGISTERED_EVENTS ? 'bold' : 'normal'}`,
            borderBottom: `2px solid ${selectedTable === EventTables.REGISTERED_EVENTS ? '#ff9900' : '#bababa'}`,
          }}
        >
          {t('Classificados')}
        </Box>
        <div style={{ display: 'flex', flex: 1, borderBottom: `2px solid #bababa` }} />
      </div>
      {selectedTable === EventTables.NOT_REGISTERED_EVENTS && (
        <NotRegisteredEvents groups={groups || []} categories={categories || []} pageOptions={[5]} />
      )}
      {selectedTable === EventTables.REGISTERED_EVENTS && (
        <RegisteredEvents groups={groups || []} categories={categories || []} pageOptions={[5]} />
      )}
    </div>
  );
}
export default Events;
