import api from '@services/api';
import { ProjectSubType } from '../models/projectSubType';
import { PaginationResponse } from '../models/pagination';

interface GetProjectSubTypesParams {
  searchTerm?: string;
  page: number;
  size: number;
  sortKey: 'code' | 'name';
  sortDirection: 'ASC' | 'DESC';
}

export const projectSubTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    getProjectSubTypes: builder.query<PaginationResponse<ProjectSubType>, GetProjectSubTypesParams>({
      query: (params: GetProjectSubTypesParams) => ({
        url: encodeURI(
          `/salary/project-subtype/search?${params.searchTerm ? `searchTerm=${params.searchTerm}` : ''}page=${
            params.page
          }&size=${params.size}&sortKey=${params.sortKey}&sortDirection=${params.sortDirection}`,
        ),
        method: 'GET',
      }),
      providesTags: ['ProjectSubType'],
    }),
  }),
});

export const { useGetProjectSubTypesQuery } = projectSubTypeApi;
