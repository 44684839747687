import api from '@services/api';
import { Category } from '@/app/models/category';

/**
 * <p>
 * API endpoints that cover all resource sharing CRUD calls
 */
export const categoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listAllCategories: builder.query<Array<Category>, void>({
      query: () => ({
        url: encodeURI(`/salary/category/listAll`),
      }),
    }),
  }),
});

export const { useListAllCategoriesQuery } = categoryApi;
