import api from '@services/api';
import { PaginationResponse } from '@/app/models/pagination';
import { Expense, QueryInput, ExpenseUpdate } from '@/app/models/expense';

/**
 * <p>
 * API endpoints that cover all resource sharing CRUD calls
 */
export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    expense: builder.query<PaginationResponse<Expense>, QueryInput>({
      query: (queryInput) => ({
        url: encodeURI(
          `/salary/expense/search?bringOnlyNew=${queryInput.bringOnlyNew}&page=${queryInput.page}&size=${
            queryInput.size
          }&${queryInput.searchTerm ? `searchTerm=${queryInput.searchTerm}&` : ''}${
            queryInput.groupId ? `groupId=${queryInput.groupId}&` : ''
          }sortKey=name&sortDirection=DESC`,
        ),
      }),
      providesTags: ['Expense', 'ExpenseException'],
    }),
    updateExpense: builder.mutation<Expense, ExpenseUpdate>({
      query: (expense) => ({
        url: `/salary/expense/update`,
        method: 'PUT',
        body: expense,
      }),
      invalidatesTags: ['Expense', 'ExpenseException'],
    }),
  }),
});

export const { useExpenseQuery, useUpdateExpenseMutation } = expenseApi;
