import api from '@services/api';
import { ExpenseGroup } from '@/app/models/expenseGroup';

/**
 * <p>
 * API endpoints that cover all resource sharing CRUD calls
 */
export const groupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listAllExpenseGroup: builder.query<Array<ExpenseGroup>, void>({
      query: () => ({
        url: encodeURI(`/salary/group/listAll`),
      }),
    }),
  }),
});

export const { useListAllExpenseGroupQuery } = groupApi;
