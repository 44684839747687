import { useGetExpenseExceptionQuery } from '@/app/services/expenseExceptionApi';
import LateralDrawer from '@/components/common/LateralDrawer';
import PeriodDetails from '@/components/events/PeriodDetails';
import BatchExceptionDrawer from '@/components/exceptions/batchExceptionDrawer';
import CopyExceptionDrawer from '@/components/exceptions/copyExceptionDrawer';
import CreateExceptionDrawer from '@/components/exceptions/createExceptionDrawer';
import ExceptionTable from '@/components/tables/ExceptionsTable';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '../personProductRoles';

function Exception() {
  const { t } = useTranslation('exceptions');
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showBatchAddModal, setShowBatchAddModal] = useState(false);
  const [showBatchRemoveModal, setShowBatchRemoveModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [editProject, setEditProject] = useState(
    null as unknown as {
      name: string;
      code: string;
      payingName: string;
      payingCode: string;
    },
  );
  const [copyProject, setCopyProject] = useState(
    null as unknown as {
      destiniCode: string;
      code: string;
      exceptions: number[];
    },
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const { data: exceptions } = useGetExpenseExceptionQuery({ searchTerm, page, size: rowsPerPage });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <PeriodDetails />
      <Typography style={{ marginTop: '32px', marginBottom: '0px' }} variant="h1">
        Exceções Eventos
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <SearchBar
          placeholder={t('Pesquisar pelo nome ou Código do Projeto...') as string}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={() => {
            setAnchorElUser(null);
          }}
        >
          <MenuItem>
            <Button
              variant="tertiary"
              onClick={() => {
                setShowCreateModal(true);
                setAnchorElUser(null);
              }}
            >{`${t('Adicionar Beneficio Especifico')}`}</Button>
          </MenuItem>
          <MenuItem>
            <Button
              variant="tertiary"
              onClick={() => {
                setShowBatchAddModal(true);
                setAnchorElUser(null);
              }}
            >{`${t('Adicionar Beneficios Gerais')}`}</Button>
          </MenuItem>
          <MenuItem>
            <Button
              variant="tertiary"
              onClick={() => {
                setShowBatchRemoveModal(true);
                setAnchorElUser(null);
              }}
            >{`${t('Remover Beneficios Gerais')}`}</Button>
          </MenuItem>
        </Menu>
        <Button variant="tertiary" onClick={handleOpenUserMenu}>{`+ ${t('Opções')}`}</Button>
      </div>
      <ExceptionTable
        setShowCreateModal={setShowCreateModal}
        setEditProject={setEditProject}
        count={exceptions?.totalElements as number}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rows={exceptions?.content || []}
        setShowCopyModal={setShowCopyModal}
        setCopyProject={setCopyProject}
      />
      <LateralDrawer
        onClose={() => {
          setShowCreateModal(false);
          setEditProject(
            null as unknown as {
              name: string;
              code: string;
              payingName: string;
              payingCode: string;
            },
          );
        }}
        width="700px"
        open={showCreateModal}
        setOpen={setShowCreateModal}
        position="right"
      >
        <CreateExceptionDrawer
          editProject={editProject}
          onClose={() => {
            setShowCreateModal(false);
            setEditProject(
              null as unknown as {
                name: string;
                code: string;
                payingName: string;
                payingCode: string;
              },
            );
          }}
        />
      </LateralDrawer>

      <LateralDrawer
        onClose={() => {
          setShowBatchAddModal(false);
        }}
        width="700px"
        open={showBatchAddModal}
        setOpen={setShowBatchAddModal}
        position="right"
      >
        <BatchExceptionDrawer
          onClose={() => {
            setShowBatchAddModal(false);
          }}
          add={true}
        />
      </LateralDrawer>

      <LateralDrawer
        onClose={() => {
          setShowBatchRemoveModal(false);
        }}
        width="700px"
        open={showBatchRemoveModal}
        setOpen={setShowBatchRemoveModal}
        position="right"
      >
        <BatchExceptionDrawer
          onClose={() => {
            setShowBatchRemoveModal(false);
          }}
          add={false}
        />
      </LateralDrawer>

      <LateralDrawer
        onClose={() => {
          setShowCopyModal(false);
          setCopyProject(
            null as unknown as {
              destiniCode: string;
              code: string;
              exceptions: number[];
            },
          );
        }}
        width="700px"
        open={showCopyModal}
        setOpen={setShowCopyModal}
        position="right"
      >
        <CopyExceptionDrawer
          copyProject={copyProject}
          onClose={() => {
            setShowCopyModal(false);
            setCopyProject(
              null as unknown as {
                destiniCode: string;
                code: string;
                exceptions: number[];
              },
            );
          }}
        />
      </LateralDrawer>
    </div>
  );
}
export default Exception;
