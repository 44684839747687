import PeriodProcessing from '@/components/processing/PeriodProcessing';
import NewProcessing from '@/components/processing/NewProcessing';
import PeriodHistory from '@/components/processing/PeriodHistory';
import { Period } from '@app/models/period';

import { useState } from 'react';

import { Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Processing() {
  const [openNewProcessing, setOpenNewProcessing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [period, setPeriod] = useState<Period | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);

  const { t } = useTranslation('processing');

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <Drawer
        onBackdropClick={() => {
          setOpenNewProcessing(false);
          setSelectedPeriod(null);
        }}
        open={!!openNewProcessing}
        anchor="right"
      >
        <NewProcessing
          period={selectedPeriod || period}
          reprocess={!!selectedPeriod}
          setOpenNewProcessing={setOpenNewProcessing}
          setProcessing={setProcessing}
          setSelectedPeriod={setSelectedPeriod}
        />
      </Drawer>
      <PeriodProcessing
        setPeriod={setPeriod}
        setOpenNewProcessing={setOpenNewProcessing}
        processing={processing}
        setProcessing={setProcessing}
      />

      <PeriodHistory
        setOpenNewProcessing={setOpenNewProcessing}
        setSelectedPeriod={setSelectedPeriod}
        pageOptions={[5]}
      />
    </div>
  );
}
export default Processing;
