import { Project } from '@/app/models/project';
import { RuleTypeConfig } from '@/app/models/ruleTypeConfig';
import { useBranchQuery } from '@/app/services/branchApi';
import { useClusterQuery } from '@/app/services/clusterApi';
import { useProjectsQuery } from '@/app/services/projectApi';
import { useGetProjectSubTypesQuery } from '@/app/services/projectSubTypeApi';
import { useGetProjectTypesQuery } from '@/app/services/projectTypeApi';
import { useCreateRuleTypeConfigMutation, useUpdateRuleTypeConfigMutation } from '@/app/services/ruleTypeConfig';
import searchIcon from '@/icons/search.svg';
import {
  Autocomplete,
  Box,
  InputAdornment,
  InputProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type CreateReversionDrawePropsProps = {
  onClose: () => void;
  editingReversion: RuleTypeConfig;
};

export default function CreateReversionDrawer(props: CreateReversionDrawePropsProps) {
  const { onClose, editingReversion } = props;

  const { data: clusters } = useClusterQuery({ page: 0, size: 100 });
  const { data: branches } = useBranchQuery({ page: 0, size: 100 });
  const { data: projectTypes } = useGetProjectTypesQuery({ page: 0, size: 100, sortKey: 'name', sortDirection: 'ASC' });
  const { data: projectSubTypes } = useGetProjectSubTypesQuery({
    page: 0,
    size: 100,
    sortKey: 'name',
    sortDirection: 'ASC',
  });

  const { t } = useTranslation('exceptions');
  const [cluster, setCluster] = useState('');
  const [branch, setBranch] = useState('');
  const [projectType, setProjectType] = useState('');
  const [projectSubtype, setProjectSubtype] = useState(null as unknown as string);

  const [costCenter, setCostCenter] = useState('');
  const [searchCostCenter, setSearchCostCenter] = useState('');
  const [selectedCostCenter, setSelectedCostCenter] = useState(
    editingReversion ? editingReversion.project : (null as unknown as Project),
  );

  const { data: costCenters } = useProjectsQuery({
    bringClose: false,
    searchTerm: searchCostCenter,
    page: 0,
    size: 10,
  });
  const [createRuleTypeConfig] = useCreateRuleTypeConfigMutation();
  const [updateRuleTypeConfig] = useUpdateRuleTypeConfigMutation();

  useEffect(() => {
    if (editingReversion) {
      setCluster(editingReversion.cluster.code);
      setBranch(editingReversion.branch.code);
      setProjectType(editingReversion.projectType.name);
      setProjectSubtype(editingReversion.projectSubType?.name || (null as unknown as string));
    }
  }, [clusters, editingReversion]);

  const handleSubmit = async () => {
    const response = createRuleTypeConfig({
      clusterCode: cluster,
      branchCode: branch,
      projectType,
      projectSubType: projectSubtype,
      projectCode: selectedCostCenter.code,
    });
    if ('data' in response) {
      toast.success(
        t('Regra de reversão criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    onClose();
  };

  const handleEdit = async () => {
    const response = updateRuleTypeConfig({
      id: editingReversion.id.toString(),
      clusterCode: cluster,
      branchCode: branch,
      projectType,
      projectSubType: projectSubtype,
      projectCode: selectedCostCenter.code,
    });
    if ('data' in response) {
      toast.success(
        t('Regra de reversão criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
    onClose();
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh )',
        maxHeight: 'calc(100vh )',
        flexGrow: 'inherit',
        background: '#FAFAFA',
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
        <Box style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '24px' }}>
          <Typography variant="h1">{t('Casos de Exceção')}</Typography>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
          <Box sx={{ flexGrow: 1, width: '50%' }}>
            <Typography variant="h3">{t('Cluster')}</Typography>
            <Select
              value={cluster}
              size="small"
              label="Selecione o Cluster"
              placeholder="Selecione o grupo"
              sx={{ width: '100%', fontSize: '13px', marginTop: '4px' }}
              onChange={(event: SelectChangeEvent<string>) => {
                setCluster(event.target.value as string);
              }}
            >
              {clusters?.content &&
                clusters.content.map((itemCluster) => (
                  <MenuItem
                    sx={{
                      fontSize: '13px',
                    }}
                    key={itemCluster.code}
                    value={itemCluster.code as any}
                  >
                    {itemCluster.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box sx={{ flexGrow: 1, width: '50%' }}>
            <Typography variant="h3">{t('Filial')}</Typography>
            <Select
              value={branch}
              size="small"
              label="Selecione a Branch"
              placeholder="Selecione a Branch"
              sx={{ width: '100%', fontSize: '13px', marginTop: '4px' }}
              onChange={(event: SelectChangeEvent<string>) => {
                setBranch(event.target.value as string);
              }}
            >
              {branches?.content &&
                branches.content.map((itemBranch) => (
                  <MenuItem
                    sx={{
                      fontSize: '13px',
                    }}
                    key={itemBranch.code}
                    value={itemBranch.code as any}
                  >
                    {itemBranch.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', gap: 2, marginTop: 3 }}>
          <Box sx={{ flexGrow: 1, width: '50%' }}>
            <Typography variant="h3">{t('Classificação de CR')}</Typography>
            <Select
              value={projectType}
              size="small"
              label="Selecione a Classificação de CR"
              placeholder="Selecione a Classificação de CR"
              sx={{ width: '100%', fontSize: '13px', marginTop: '4px' }}
              onChange={(event: SelectChangeEvent<string>) => {
                setProjectType(event.target.value as string);
              }}
            >
              {projectTypes?.content &&
                projectTypes.content.map((itemProjectType) => (
                  <MenuItem
                    sx={{
                      fontSize: '13px',
                    }}
                    key={itemProjectType.code}
                    value={itemProjectType.name as any}
                  >
                    {itemProjectType.name}
                  </MenuItem>
                ))}
            </Select>
          </Box>
          <Box sx={{ flexGrow: 1, width: '50%' }}>
            <Typography variant="h3">{t('Tipo de Rateio')}</Typography>
            <Select
              value={projectSubtype}
              size="small"
              label="Selecione o Tipo de Rateio"
              placeholder="Selecione o Tipo de Rateio"
              sx={{ width: '100%', fontSize: '13px', marginTop: '4px' }}
              onChange={(event: SelectChangeEvent<string>) => {
                setProjectSubtype(event.target.value as string);
              }}
            >
              {projectSubTypes?.content &&
                projectSubTypes.content.map((projectSubType) => (
                  <MenuItem
                    sx={{
                      fontSize: '13px',
                    }}
                    key={projectSubType.code}
                    value={projectSubType?.name || (null as any)}
                  >
                    {projectSubType?.name || '---'}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
        <Typography variant="h3" sx={{ marginTop: 3 }}>
          {t('Projeto')}
        </Typography>
        <Autocomplete
          freeSolo
          filterOptions={(option) => option}
          isOptionEqualToValue={(option, value) => option?.code === value?.code}
          style={{ width: '100%', marginRight: '10px', marginTop: '8px' }}
          size="small"
          defaultValue={selectedCostCenter}
          onChange={(event, newValue) => {
            setSelectedCostCenter(newValue as Project);
          }}
          inputValue={costCenter}
          onInputChange={(event, newInputValue) => {
            setCostCenter(newInputValue);
            if (newInputValue.length >= 1) {
              setSearchCostCenter(newInputValue);
            }
          }}
          options={costCenters?.content || []}
          getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.code} - ${option.name} `)}
          renderOption={(params, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
              {`${option.code} - ${option.name} `}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              sx={{
                '& input': {
                  padding: '0px',
                },
              }}
              size="small"
              style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
              ref={params.InputProps.ref}
              InputProps={
                {
                  ...params.inputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                    </InputAdornment>
                  ),
                  style: {
                    paddingRight: '10px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    paddingLeft: '10px',
                    fontSize: '13px',
                  },
                } as InputProps
              }
              autoFocus
              placeholder={t('Digite nome ou código do centro de custo') as string}
            />
          )}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancelar')}
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          disabled={!(cluster && branch && selectedCostCenter && projectType)}
          variant="primary"
          onClick={() => {
            if (editingReversion) {
              handleEdit();
            } else {
              handleSubmit();
            }
          }}
        >
          {editingReversion ? t('Salvar') : t('Adicionar')}
        </Button>
      </Box>
    </Box>
  );
}
