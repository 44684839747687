interface PermissionRole {
  [key: string]: Array<string>;
}

interface PageName {
  [key: string]: string;
}

/**
 * Since every page is accessed only if the user owns certain roles,
 * we must define a set of Pages and the roles of every page
 */

export const PAGE_NAMES: PageName = {
  CONTROLADORIA: 'CONTROLADORIA',
  ADMIN: 'ADMIN',
};

const PERMISSION_ROLES: PermissionRole = {
  CONTROLADORIA: ['CONTROLADORIA'],
  ADMIN: ['ADMIN'],
};

export default PERMISSION_ROLES;
