import { Autocomplete, Box, InputAdornment, InputProps, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import searchIcon from '@/icons/search.svg';
import { useExpenseQuery } from '@/app/services/expenseApi';
import { Expense } from '@/app/models/expense';

import CancelOrConfirmDialog from '../common/dialogs/CancelOrConfirmDialog';
import BatchExceptionsTable from '../tables/BatchExceptionsTable';
import {
  useCreateBatchExpenseExceptionMutation,
  useDeleteBatchExpenseExceptionMutation,
} from '@/app/services/expenseExceptionApi';
import { toast } from 'react-toastify';

type BatchExceptionDrawerProps = {
  onClose: () => void;
  add: boolean;
};

export default function BatchExceptionDrawer(props: BatchExceptionDrawerProps) {
  const { onClose, add } = props;

  const { t } = useTranslation('exceptions');

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [expense, setExpense] = useState('');
  const [searchExpense, setSearchExpense] = useState('');
  const [selectedExpense, setSelectedExpense] = useState(null as unknown as Expense);
  const [addedItens, setAddedItens] = useState([] as Expense[]);

  const [addBatchExceptions] = useCreateBatchExpenseExceptionMutation();
  const [deleteBatchExceptions] = useDeleteBatchExpenseExceptionMutation();

  const { data: expenses } = useExpenseQuery({ searchTerm: searchExpense, bringOnlyNew: false, page: 0, size: 10 });

  const handleSubmitAdd = async () => {
    let addingCodes = addedItens.map((i) => i.code);
    let response = addBatchExceptions({ codes: addingCodes });
    if ('data' in response) {
      toast.success(
        t('Caso de Exceção geral criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };
  const handleSubmitRemove = async () => {
    let removingCodes = addedItens.map((i) => i.code);
    let response = deleteBatchExceptions({ codes: removingCodes });
    if ('data' in response) {
      toast.success(
        t('Caso de Exceção geral removido com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  const handleAddToList = async (expense: Expense) => {
    let newExpenses = [...addedItens];
    newExpenses.push(expense);
    setAddedItens(newExpenses);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh )',
        maxHeight: 'calc(100vh )',
        flexGrow: 'inherit',
        background: '#FAFAFA',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
        <div style={{ marginTop: '32px', marginBottom: '19px' }}>
          <Typography variant="h3">
            {add ? t('Adicionar Benefícios Gerais') : t('Remover Benefícios Gerais')}
          </Typography>
          <Typography variant="subtitle1">
            {add
              ? t('Ao inserir os benefícios abaixo eles serão adicionados a todos os casos de exceção existentes')
              : t('Ao inserir os benefícios abaixo eles serão removidos a todos os casos de exceção existentes')}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Autocomplete
              freeSolo
              filterOptions={(option) => option}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              style={{ width: '100%', marginRight: '10px', marginTop: '8px' }}
              size="small"
              defaultValue={selectedExpense}
              onChange={(event, newValue) => {
                setSelectedExpense(newValue as Expense);
              }}
              inputValue={expense}
              onInputChange={(event, newInputValue) => {
                setExpense(newInputValue);
                if (newInputValue.length >= 1) {
                  setSearchExpense(newInputValue);
                }
              }}
              options={expenses?.content || []}
              getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.code}`)}
              renderOption={(params, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                  {`${option.code}`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& input': {
                      padding: '0px',
                    },
                  }}
                  size="small"
                  style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                  ref={params.InputProps.ref}
                  InputProps={
                    {
                      ...params.inputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        fontSize: '13px',
                      },
                    } as InputProps
                  }
                  autoFocus
                  placeholder={t('Pesquisar pelo código do evento') as string}
                />
              )}
            />
            <Button
              disabled={!!!selectedExpense}
              sx={{ marginLeft: '24px', marginRight: '16px' }}
              variant="tertiary"
              onClick={() => {
                handleAddToList(selectedExpense);
              }}
            >
              {t('+Adicionar')}
            </Button>
          </div>
          <div style={{ display: 'flex', marginTop: '24px' }}>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Código')}
              </Typography>
              <Typography variant="h3">{selectedExpense ? selectedExpense.code : '---'}</Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Rateável')}
              </Typography>
              <Typography variant="h3">
                {(selectedExpense && (selectedExpense.isShareable ? t('Sim') : t('Não'))) || '---'}
              </Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Lançamento')}
              </Typography>
              <Typography variant="h3">
                {(selectedExpense && (selectedExpense.isCredit ? t('Crédito') : t('Débito'))) || '---'}
              </Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Categoria')}
              </Typography>
              <Typography variant="h3">{selectedExpense ? selectedExpense.category?.name : '---'}</Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Grupo')}
              </Typography>
              <Typography variant="h3">{selectedExpense ? selectedExpense.expenseGroup?.name : '---'}</Typography>
            </div>
          </div>
          <div style={{ width: '100%', border: '1px solid #CCCCCC', marginTop: '32px' }} />
          <BatchExceptionsTable rows={addedItens} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancelar')}
        </Button>

        <Button
          disabled={addedItens.length <= 0}
          variant="primary"
          onClick={() => {
            setConfirmDialog(true);
          }}
          sx={{ marginLeft: '16px' }}
        >
          {t('Salvar')}
        </Button>
      </div>
      <CancelOrConfirmDialog
        open={confirmDialog}
        title={add ? t('Adicionar Regra Geral') : t('Remover Regra Geral')}
        contentText={
          add
            ? t('Todas as exceções vão ser adicionadas a todos os projetos, tem certeza que deseja editar?')
            : t('Todas as exceções vão ser removidas de todos os projetos, tem certeza que deseja editar?')
        }
        onClose={() => {
          setConfirmDialog(false);
        }}
        onConfirm={() => {
          if (add) {
            handleSubmitAdd();
          } else {
            handleSubmitRemove();
          }

          onClose();
        }}
        cancelText={t('Cancelar')}
        confirmText={t('Confirmar')}
      />
    </div>
  );
}
