import { Children, cloneElement, ReactElement, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SvgIconProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

interface ActionItemProps {
  text: string;
  Icon: React.ElementType<SvgIconProps>;
  onClick: () => void;
}

export function ItemAction(props: ActionItemProps) {
  const { text, Icon, onClick } = props;
  return (
    <MenuItem autoFocus onClick={onClick} sx={{ pl: 1.5, pr: 3, pt: '2px', pb: '2px' }}>
      <ListItemIcon>
        <Icon fontSize="small" />
      </ListItemIcon>
      <Typography fontSize={13} alignSelf="center">
        {text}
      </Typography>
    </MenuItem>
  );
}

interface ActionMenuProps {
  children: ReactElement<ActionItemProps>[] | ReactElement<ActionItemProps>;
}

function ActionMenu(props: ActionMenuProps) {
  const { children } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Ações">
        <IconButton onClick={handleClick}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              border: '1px solid #CCCCCC',
              boxShadow: 3,
              ml: -4,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: '50%',
                right: 0,
                width: 10,
                height: 10,
                borderTop: '1px solid #CCCCCC',
                borderRight: '1px solid #CCCCCC',
                bgcolor: 'background.paper',
                transform: `translateY(-50%) translateX(50%) rotate(45deg)`,
                zIndex: 0,
              },
            },
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {Children.map(children, (child) =>
          cloneElement(child, {
            onClick: () => {
              // child.props.autoFocus = true;
              child.props.onClick();
              handleClose();
            },
          }),
        )}
      </Menu>
    </>
  );
}

export default ActionMenu;
