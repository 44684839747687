import api from '@services/api';
import { ProjectType } from '../models/projectType';
import { PaginationResponse } from '../models/pagination';

interface GetProjectTypesParams {
  searchTerm?: string;
  page: number;
  size: number;
  sortKey: 'code' | 'name';
  sortDirection: 'ASC' | 'DESC';
}

export const projectTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    getProjectTypes: builder.query<PaginationResponse<ProjectType>, GetProjectTypesParams>({
      query: (params: GetProjectTypesParams) => ({
        url: encodeURI(
          `/salary/project-type/search?${params.searchTerm ? `searchTerm=${params.searchTerm}` : ''}page=${
            params.page
          }&size=${params.size}&sortKey=${params.sortKey}&sortDirection=${params.sortDirection}`,
        ),
        method: 'GET',
      }),
      providesTags: ['ProjectType'],
    }),
  }),
});

export const { useGetProjectTypesQuery } = projectTypeApi;
