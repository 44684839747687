import api from '@services/api';
import { PaginationResponse } from '../models/pagination';
import CreateRuleTypeConfig, { RuleTypeConfig } from '../models/ruleTypeConfig';

type GetAllExpenses = {
  searchTerm?: string;
  page: number;
  size: number;
  sortKey?: string;
  sortDirection?: string;
};

type UpdateRuleTypeConfig = {
  id: string;
  projectCode: string;
  clusterCode: string;
  branchCode: string;
  projectType: string;
  projectSubType: string;
};

export const ruleTypeConfig = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getRuleType: builder.query<PaginationResponse<RuleTypeConfig>, GetAllExpenses>({
      query: (input: GetAllExpenses) => ({
        url: encodeURI(
          `/salary/rule-type-config/search?page=${input.page}&size=${input.size}${
            input.searchTerm ? `&searchTerm=${input.searchTerm}` : ''
          }${input.sortKey ? `&sortKey=${input.sortKey}` : ''}${
            input.sortDirection ? `&sortDirection=${input.sortDirection}` : ''
          }`,
        ),
        method: 'GET',
      }),
      providesTags: ['RuleType'],
    }),
    // CREATE
    createRuleTypeConfig: builder.mutation<RuleTypeConfig, CreateRuleTypeConfig>({
      query: (input: CreateRuleTypeConfig) => ({
        url: '/salary/rule-type-config/create',
        method: 'POST',
        body: input,
      }),
      invalidatesTags: ['RuleType'],
    }),
    // UPDATE
    updateRuleTypeConfig: builder.mutation<RuleTypeConfig, UpdateRuleTypeConfig>({
      query: (input: UpdateRuleTypeConfig) => ({
        url: `/salary/rule-type-config/update`,
        method: 'PUT',
        body: input,
      }),
      invalidatesTags: ['RuleType'],
    }),

    // DELETE
    deleteRuleTypeConfig: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/salary/rule-type-config/delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RuleType'],
    }),
  }),
});

export const {
  useGetRuleTypeQuery,
  useCreateRuleTypeConfigMutation,
  useUpdateRuleTypeConfigMutation,
  useDeleteRuleTypeConfigMutation,
} = ruleTypeConfig;
