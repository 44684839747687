import getDb from '@/app/firebase/firebase';
import { getProcessRequestSubscription } from '@/app/helpers/firebaseCollections';
import { usePeriodChangeStatusMutation } from '@/app/services/notificationsApi';
import { useCurrentPeriodQuery } from '@/app/services/periodApi';
import { Period } from '@app/models/period';
import formatDateMonthYear, { formatDate, formatTime } from '@helpers/date';
import { Button, Card, CardContent, styled, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { collection, deleteDoc, DocumentChange, onSnapshot, query, Unsubscribe } from 'firebase/firestore';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type CallbackUpdateStateFunction = (a: SetStateAction<Period | null>) => void;
type CallbackUpdateStateBooleanFunction = (a: SetStateAction<boolean>) => void;

type PeriodProcessingType = {
  setOpenNewProcessing: CallbackUpdateStateBooleanFunction;
  setPeriod: CallbackUpdateStateFunction;
  processing: boolean;
  setProcessing: (b: boolean) => void;
};

type ErrorTooltipProps = {
  children: React.ReactElement<any, any>;
  open?: boolean;
  error?: string | null;
};

const ErrorTooltip = styled(({ children, open, error }: ErrorTooltipProps) =>
  open ? (
    <Tooltip
      arrow
      PopperProps={{
        disablePortal: true,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#FFFFFF',

            border: '1px solid #D9D9D9',
            '& .MuiTooltip-arrow': {
              color: '#FFFFFF',
              '&:before': {
                border: '1px solid #D9D9D9',
              },
            },
          },
        },
      }}
      placement="top"
      title={
        <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              textAlign: 'start',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                fontSize: '13px',
                color: '#666666',
                marginLeft: '8px',
              }}
            >
              {error}
            </Typography>
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  ) : (
    <div>{children}</div>
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    padding: '1px',
  },
}));

function PeriodProcessing({ setOpenNewProcessing, setPeriod, processing, setProcessing }: PeriodProcessingType) {
  const { i18n, t } = useTranslation('processing');
  const [currentPeriod, setCurrentPeriod] = useState<Period>();
  const { data, isSuccess } = useCurrentPeriodQuery(0);
  const [periodChangeStatus] = usePeriodChangeStatusMutation();
  let unsubscribe: Unsubscribe;
  const addedType = 'added';

  const listeningStatusUpdate = (): Unsubscribe => {
    const db = getDb();
    const q = query(collection(db, getProcessRequestSubscription()));
    periodChangeStatus();
    console.log('subscribed');
    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((item: DocumentChange) => {
        if (item.type === addedType) {
          periodChangeStatus();
          setProcessing(false);
          deleteDoc(item.doc.ref);
          toast.success(
            t('Processamento Finalizado', {
              position: toast.POSITION.TOP_RIGHT,
            }),
          );
          console.log('KILLING UNSUBSCRIPTION');
          if (unsubscribe) {
            unsubscribe();
          }
        }
      });
    });
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'ERROR':
        return '#FF0000';
      case 'OK':
        return '#28A745';
      case 'SENT':
        return '#FFC107';
      default:
        return '#888888';
    }
  };

  useEffect(
    () => () => {
      if (unsubscribe) {
        unsubscribe();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const sentStatus = 'SENT';
  useEffect(() => {
    if (currentPeriod?.status === sentStatus) {
      setProcessing(true);
    }
    if (processing) {
      unsubscribe = listeningStatusUpdate();
    }
    return () => {};
  }, [processing, currentPeriod]);

  useEffect(() => {
    setCurrentPeriod(data?.content && data.content[0]);
    setPeriod((data?.content && data?.content.length > 0 && data.content[0]) || null);
  }, [data, setPeriod]);

  return (
    <Card sx={{ boxShadow: 3, display: 'flex', minHeight: '72px', alignItems: 'center', padding: '0 8px' }}>
      {isSuccess && (
        <CardContent sx={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography sx={{ fontSize: 13, color: '#4B4B4B' }} color="text.secondary" gutterBottom>
              {t('Período Corrente')}
            </Typography>
            <Typography sx={{ fontSize: 13, fontWeight: 'bold', color: '#4B4B4B' }} color="text.secondary" gutterBottom>
              {formatDateMonthYear(currentPeriod?.date, i18n.language)}
            </Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography sx={{ fontSize: 13, color: '#4B4B4B' }} color="text.secondary" gutterBottom>
              {t('Última Sincronização')}
            </Typography>
            <Typography sx={{ fontSize: 13, fontWeight: 'bold', color: '#4B4B4B' }} color="text.secondary" gutterBottom>
              {`${formatDate(currentPeriod?.salaryEventsSynchronizedDate, i18n.language)} ${t('às')} ${formatTime(
                currentPeriod?.salaryEventsSynchronizedDate,
              )}` || t('Não sincronizado')}
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography sx={{ fontSize: 13, color: '#4B4B4B' }} color="text.secondary" gutterBottom>
              {t('Rateio Direto')}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: `${currentPeriod?.directSharingProcessed ? '#28A745' : '#FFC107'}`,
                  width: '8px',
                  height: '8px',
                  borderRadius: '4px',
                  marginRight: '6px',
                }}
              />
              <Typography
                sx={{ fontSize: 13, fontWeight: 'bold', color: '#4B4B4B', margin: '0px' }}
                color="text.secondary"
                gutterBottom
              >
                {currentPeriod?.directSharingProcessed ? t('Processado') : t('Não Processado')}
              </Typography>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography sx={{ fontSize: 13, color: '#4B4B4B' }} color="text.secondary" gutterBottom>
              {t('Rateio Indireto')}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: `${currentPeriod?.indirectSharingProcessed ? '#28A745' : '#FFC107'}`,
                  width: '8px',
                  height: '8px',
                  borderRadius: '4px',
                  marginRight: '6px',
                }}
              />
              <Typography
                sx={{ fontSize: 13, fontWeight: 'bold', color: '#4B4B4B', margin: '0px' }}
                color="text.secondary"
                gutterBottom
              >
                {currentPeriod?.indirectSharingProcessed ? t('Processado') : t('Não Processado')}
              </Typography>
            </div>
          </div>

          <ErrorTooltip open={currentPeriod?.status === 'ERROR'} error={currentPeriod?.errorMessage}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography sx={{ fontSize: 13, color: '#4B4B4B' }} color="text.secondary" gutterBottom>
                {t('Status')}
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                  style={{
                    backgroundColor: `${getStatusColor(currentPeriod?.status || '')}`,
                    width: '8px',
                    height: '8px',
                    borderRadius: '4px',
                    marginRight: '6px',
                  }}
                />
                <Typography
                  sx={{ fontSize: 13, fontWeight: 'bold', color: '#4B4B4B', margin: '0px' }}
                  color="text.secondary"
                  gutterBottom
                >
                  {t(currentPeriod?.status || ('Not Sent' as string))}
                </Typography>
              </div>
            </div>
          </ErrorTooltip>

          <Button
            onClick={() => setOpenNewProcessing(true)}
            variant="primary"
            style={{
              marginLeft: '16px',
            }}
            disabled={processing}
          >
            {t('Processar')}
          </Button>
        </CardContent>
      )}
    </Card>
  );
}

export default PeriodProcessing;
