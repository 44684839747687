import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, RadioGroup, FormControlLabel, Radio, Checkbox, Input, Typography } from '@mui/material';

import { toast } from 'react-toastify';
import { useUploadFileMutation, useStartProcessingMutation } from '@/app/services/processApi';

import formatDateMonthYear, { formatDate, formatTime } from '@helpers/date';
import { Period } from '@app/models/period';
import StandardDialog from '@/components/common/StandardDialog';

type CallbackUpdateFunction = (a: boolean) => void;

type PropsNewProcessing = {
  setOpenNewProcessing: CallbackUpdateFunction;
  setProcessing: CallbackUpdateFunction;
  setSelectedPeriod: Dispatch<SetStateAction<Period | null>>;
  period: Period | null;
  reprocess: boolean;
};

function NewProcessing(props: PropsNewProcessing) {
  const { setOpenNewProcessing, setProcessing, period, setSelectedPeriod, reprocess } = props;
  const { i18n, t } = useTranslation('processing');

  const [value, setValue] = useState('direto');
  const [saveToBucket, setSaveToBucket] = useState(false);

  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [uploadFile] = useUploadFileMutation();
  const [startProcessing] = useStartProcessingMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const process = async () => {
    const direct = value === 'direto';
    setOpenNewProcessing(false);
    setProcessing(true);
    let response = {};
    if (!direct) {
      const formData = new FormData();
      formData.append('file', selectedFile || '');
      formData.append('period', period?.date as string);
      formData.append('saveToBucket', saveToBucket.toString());
      response = await uploadFile(formData);
    } else {
      response = await startProcessing({ saveToBucket, period: period?.date as string });
    }
    if ('data' in response) {
      toast.success(
        t('Processando. Aguarde ...', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    } else {
      toast.error(
        t('Falha no processamento!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        margin: '24px',
        width: '500px',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <StandardDialog
        title={t('Processar')}
        contentText={t('Tem certeza que deseja processar este tipo de rateio?')}
        open={openSaveDialog}
        onClose={() => {
          setOpenSaveDialog(false);
          setSaveToBucket(false);
        }}
        onConfirm={() => {
          setOpenSaveDialog(false);
          process();
        }}
        cancelText={t('Não')}
        confirmText={t('Sim')}
      >
        <div>
          <FormControlLabel
            sx={{ whiteSpace: 'nowrap', fontSize: '12px', marginTop: '22px' }}
            control={
              <Checkbox
                color="secondary"
                checked={saveToBucket}
                onChange={() => {
                  setSaveToBucket(!saveToBucket);
                }}
              />
            }
            label={<Typography sx={{ fontSize: '12px' }}>{t('Fazer download do arquivo ao processar')}</Typography>}
          />
        </div>
      </StandardDialog>
      <div>
        <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{t(reprocess ? 'Reprocessar' : 'Processar')}</div>
        <div style={{ display: 'flex' }}>
          <div>
            <div style={{ marginTop: '16px', fontSize: '13px' }}>{t('Período Corrente')}</div>
            <div style={{ marginTop: '2px', fontSize: '13px', fontWeight: 'bold' }}>
              {formatDateMonthYear(period?.date, i18n.language)}
            </div>
          </div>
          <div style={{ marginLeft: '40px' }}>
            <div style={{ marginTop: '16px', fontSize: '13px' }}>{t('Última Sincronização')}</div>
            <div style={{ marginTop: '2px', fontSize: '13px', fontWeight: 'bold' }}>
              {`${formatDate(period?.salaryEventsSynchronizedDate, i18n.language)} ${t('às')} ${formatTime(
                period?.salaryEventsSynchronizedDate,
              )}` || t('Não sincronizado')}
            </div>
          </div>
        </div>

        <div style={{ margin: '24px 0px 44px 0px', fontSize: '14px', fontWeight: 'bold' }}>
          {t('Escolha o tipo de Rateio que deseja processar')}
        </div>
        <div style={{ marginBottom: '12px', fontSize: '13px' }}>
          <RadioGroup
            defaultValue="direto"
            name="radio-buttons-group"
            value={value}
            onChange={handleChange}
            sx={{
              border: 'none',
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              },
              '& .Mui-checked': {
                color: '#ff7b00 !important',
              },
              '& .MuiFormControlLabel-label': {
                fontSize: '13px',
              },
              fontSize: '13px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '20px 24px',
                backgroundColor: `${value === 'direto' ? '#F4F4F4' : '#FFFFFF'}`,
              }}
            >
              <div style={{ paddingRight: '32px', alignItems: 'center' }}>
                <FormControlLabel value="direto" control={<Radio />} label={t('Rateio Direto')} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '4px 24px',
                  borderLeft: '2px solid #CCCCCC',
                }}
              >
                <div>{t('Status')}</div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div
                    style={{
                      backgroundColor: `${period?.directSharingProcessed ? '#28A745' : '#FFC107'}`,
                      width: '8px',
                      height: '8px',
                      borderRadius: '4px',
                      marginRight: '6px',
                    }}
                  />
                  <Typography
                    sx={{ fontSize: 13, fontWeight: 'bold', color: '#4B4B4B', margin: '0px' }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {period?.directSharingProcessed ? t('Processado') : t('Não Processado')}
                  </Typography>
                </div>
              </div>
            </div>
            <div style={{ margin: '8px 0px', width: '100%', height: '2px', backgroundColor: '#CCCCCC' }} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 24px',
                maxWidth: '100%',
                backgroundColor: `${value === 'indireto' ? '#F4F4F4' : '#FFFFFF'}`,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '12px',
                }}
              >
                <div style={{ paddingRight: '32px' }}>
                  <FormControlLabel value="indireto" control={<Radio />} label={t('Rateio Indireto')} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '4px 24px',
                    borderLeft: '2px solid #CCCCCC',
                  }}
                >
                  <div>{t('Status')}</div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div
                      style={{
                        backgroundColor: `${period?.indirectSharingProcessed ? '#28A745' : '#FFC107'}`,
                        width: '8px',
                        height: '8px',
                        borderRadius: '4px',
                        marginRight: '6px',
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 13, fontWeight: 'bold', color: '#4B4B4B', margin: '0px' }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {period?.indirectSharingProcessed ? t('Processado') : t('Não Processado')}
                    </Typography>
                  </div>
                </div>
              </div>
              <label
                htmlFor="upload-file"
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                <Input
                  type="file"
                  id="upload-file"
                  style={{ display: 'none' }}
                  placeholder={t('Nenhum arquivo selecionado') as string}
                  disabled={value !== 'indireto'}
                  onChange={(e) => {
                    setSelectedFile(((e.target as HTMLInputElement).files || [null])[0]);
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    maxWidth: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      maxWidth: 'calc(100% - 118px)',
                      flex: '1',
                    }}
                  >
                    <span
                      style={{
                        display: 'block',
                        width: '100%',
                        padding: '0px 10px',
                        height: '24px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #CCCCCC',
                        fontSize: '13px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        borderRadius: '4px 0px 0px 4px',
                        color: `${value === 'indireto' ? '#4B4B4B' : '#CCCCCC'}`,
                      }}
                    >
                      {selectedFile?.name}
                    </span>
                  </div>
                  <span
                    style={{
                      display: 'flex',
                      width: '118px',
                      color: `${value === 'indireto' ? '#4B4B4B' : '#CCCCCC'}`,
                      fontSize: '13px',
                      border: '1px solid #CCCCCC',
                      height: '24px',
                      padding: '0px 8px',
                      borderRadius: '0px 4px 4px 0px',
                      whiteSpace: 'nowrap',
                      verticalAlign: 'center',
                      cursor: `${value === 'indireto' ? 'pointer' : 'default'}`,
                    }}
                  >
                    {t('Escolher Arquivo')}
                  </span>
                </div>
              </label>
            </div>
          </RadioGroup>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            setOpenNewProcessing(false);
            setSelectedPeriod(null);
          }}
          variant="secondary"
          style={{
            marginLeft: '16px',
          }}
        >
          {t('Cancelar')}
        </Button>
        <Button
          disabled={value === 'indireto' && !selectedFile}
          onClick={() => setOpenSaveDialog(true)}
          variant="primary"
          style={{
            marginLeft: '16px',
          }}
        >
          {t('Processar')}
        </Button>
      </div>
    </div>
  );
}

export default NewProcessing;
