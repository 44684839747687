import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { Expense } from '@/app/models/expense';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type BatchExceptionsTableProps = {
  rows: Array<Expense>;
};

function BatchExceptionsTable(props: BatchExceptionsTableProps) {
  const { rows } = props;
  const { t } = useTranslation('exceptions');
  return (
    <TableContainer>
      <Table size="small" aria-label="Exception Case Table">
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('Código')}</HeaderTableCell>
            <HeaderTableCell>{t('Rateável')}</HeaderTableCell>
            <HeaderTableCell>{t('Lançamento')}</HeaderTableCell>
            <HeaderTableCell>{t('Categoria')}</HeaderTableCell>
            <HeaderTableCell>{t('Grupo')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell>{row.code}</RowTableCell>
              <RowTableCell>{row.isShareable ? t('Sim') : t('Não')}</RowTableCell>
              <RowTableCell>{row.isCredit ? t('Crédito') : t('Débito')}</RowTableCell>
              <RowTableCell>{row.category?.name}</RowTableCell>
              <RowTableCell>{row.expenseGroup?.name}</RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                <Typography variant="subtitle2">{t('Nenhum evento adicionado')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BatchExceptionsTable;
