import api from '@services/api';
import { FileResponse } from '../models/sharingResult';

interface Processing {
  period: string;
  saveToBucket: boolean;
}

/**
 * <p>
 * API endpoints that cover all resource period file related CRUD calls
 */
export const periodFile = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    uploadFile: builder.mutation<any, FormData>({
      query: (formData) => ({
        url: encodeURI(`/salary/sharing-result/upload`),
        method: 'POST',
        body: formData,
      }),
    }),
    startProcessing: builder.mutation<any, Processing>({
      query: (processing) => ({
        url: encodeURI(`/salary/sharing-result/start`),
        method: 'POST',
        body: processing,
      }),
      invalidatesTags: ['Periods'],
    }),
    generateSignedDownloadLink: builder.mutation<FileResponse, string | undefined>({
      query: (period) => ({
        url: encodeURI(`/salary/sharing-result/generateSignedLink`),
        method: 'POST',
        body: { period },
      }),
    }),
    generateSignedLink: builder.query<FileResponse, string | undefined>({
      query: (period) => ({
        url: encodeURI(`/salary/sharing-result/generateSignedLink`),
        method: 'POST',
        body: { period },
      }),
    }),
  }),
});

export const {
  useUploadFileMutation,
  useStartProcessingMutation,
  useGenerateSignedLinkQuery,
  useGenerateSignedDownloadLinkMutation,
} = periodFile;
