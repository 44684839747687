import { EmployeeEventsResponse } from '@/app/models/Shared';
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 'bold',
  borderBottom: 'none',
  padding: theme.spacing(1),
}));

const RowTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 13,
  backgroundColor: 'white',
  borderBottom: 'none',
  padding: theme.spacing(1),
}));

const Separator = styled('div')({
  height: '2px',
  backgroundColor: '#bababa',
  width: '100%',
  margin: 0,
  padding: 0,
});

type EmployeeEventsGridParams = {
  rows: EmployeeEventsResponse[];
};

function EmployeeEventsTable(params: EmployeeEventsGridParams) {
  const { t } = useTranslation('employeeEvents');

  const { rows } = params;
  return (
    <TableContainer sx={{ marginLeft: 0, padding: 0, width: '100%' }}>
      <Table sx={{ tableLayout: 'fixed', width: '60%' }}>
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('badge')}</HeaderTableCell>
            <HeaderTableCell>{t('value')}</HeaderTableCell>
            <HeaderTableCell>{t('event.group')}</HeaderTableCell>
            <HeaderTableCell>{t('event.code')}</HeaderTableCell>
          </TableRow>
        </TableHead>
      </Table>
      <Separator />
      <Table sx={{ tableLayout: 'fixed', width: '60%' }}>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.badge}>
              <RowTableCell>{row.badge}</RowTableCell>
              <RowTableCell>{row.value}</RowTableCell>
              <RowTableCell>{row.group}</RowTableCell>
              <RowTableCell>{row.expenseCode}</RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={4}>
                <Typography variant="subtitle2">{t('select.the.employee.badge')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EmployeeEventsTable;
