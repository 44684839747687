import api from '@services/api';
import { PaginationResponse } from '../models/pagination';
import {
  CreateProjectPaymentLimitQuery,
  CreateProjectPaymentLimitResponse,
  ProjectPaymentLimit,
  ProjectPaymentLimitByCode,
  UpdateProjectPaymentLimitQuery,
} from '../models/projectPaymentLimit';

interface SearchProjectPaymentLimitParams {
  searchTerm?: string;
  page: number;
  size: number;
  sortKey: 'code' | 'name';
  sortDirection: 'ASC' | 'DESC';
}

export const projectPaymentLimitApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    searchProjectPaymentLimit: builder.query<PaginationResponse<ProjectPaymentLimit>, SearchProjectPaymentLimitParams>({
      query: (params: SearchProjectPaymentLimitParams) => ({
        url: encodeURI(
          `/salary/project-payment-limit/search?${params.searchTerm ? `searchTerm=${params.searchTerm}` : ''}&page=${
            params.page
          }&size=${params.size}&sortKey=${params.sortKey}&sortDirection=${params.sortDirection}`,
        ),
        method: 'GET',
      }),
      providesTags: ['ProjectPaymentLimit'],
    }),
    byIdProjectPaymentLimit: builder.query<ProjectPaymentLimitByCode[], string>({
      query: (code: string) => ({
        url: encodeURI(`/salary/project-payment-limit/findByPayingProjectCode?code=${code}`),
        method: 'GET',
      }),
      providesTags: ['ProjectPaymentLimit'],
    }),
    createProjectPaymentLimit: builder.mutation<CreateProjectPaymentLimitResponse, CreateProjectPaymentLimitQuery>({
      query: (body: CreateProjectPaymentLimitQuery) => ({
        url: encodeURI(`/salary/project-payment-limit/create`),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProjectPaymentLimit'],
    }),
    updateProjectPaymentLimit: builder.mutation<CreateProjectPaymentLimitResponse, UpdateProjectPaymentLimitQuery>({
      query: (body: UpdateProjectPaymentLimitQuery) => ({
        url: encodeURI(`/salary/project-payment-limit/update`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ProjectPaymentLimit'],
    }),
    deleteProjectPaymentLimit: builder.mutation<void, number>({
      query: (id: number) => ({
        url: encodeURI(`/salary/project-payment-limit/delete?id=${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectPaymentLimit'],
    }),
    deleteAllProjectPaymentLimit: builder.mutation<void, string>({
      query: (code: string) => ({
        url: encodeURI(`/salary/project-payment-limit/deleteAll?paymentProjectCode=${code}`),
        method: 'DELETE',
      }),
      invalidatesTags: ['ProjectPaymentLimit'],
    }),
  }),
});

export const {
  useSearchProjectPaymentLimitQuery,
  useByIdProjectPaymentLimitQuery,
  useCreateProjectPaymentLimitMutation,
  useUpdateProjectPaymentLimitMutation,
  useDeleteProjectPaymentLimitMutation,
  useDeleteAllProjectPaymentLimitMutation,
} = projectPaymentLimitApi;
