import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeView from '@mui/lab/TreeView';
import { Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import SidebarItem from './SidebarItem';

export default function Sidebar() {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('sidebar');
  const isAdmin =
    keycloak.hasResourceRole('ADMIN', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) || process.env.REACT_APP_ENV === 'dev';
  const isControladoria =
    keycloak.hasResourceRole('CONTROLADORIA', process.env.REACT_APP_KEYCLOAK_CLIENT_ID) ||
    process.env.REACT_APP_ENV === 'dev';

  const selectedTree = (endpoint: string) => {
    switch (endpoint) {
      case '/events':
        return '1';
      case '/processing':
        return '2';
      case '/general':
        return '3';
      case '/permission-by-product':
        return '4';
      case '/exception':
        return '5';
      case '/reversions':
        return '6';
      case '/projectExceptions':
        return '7';
      case '/notShared':
        return '8';
      case '/employeeEvents':
        return '9';
      default:
        return '1';
    }
  };
  return (
    <TreeView
      selected={selectedTree(location.pathname)}
      aria-label="gmail"
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, width: 280, paddingLeft: '2rem' }}
    >
      {isControladoria && (
        <>
          <Typography variant="h2" sx={{ marginY: '1rem', fontWeight: 'bold' }}>
            {t('Contabilização')}
          </Typography>
          <SidebarItem
            active={selectedTree(location.pathname) === '1'}
            nodeId="1"
            labelText={t('Eventos')}
            labelInfo="2,294"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            onClick={() => {
              navigate('events');
            }}
          />
          <SidebarItem
            active={selectedTree(location.pathname) === '2'}
            nodeId="2"
            labelText={t('Processamento')}
            labelInfo="3,566"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            onClick={() => {
              navigate('processing');
            }}
          />
          <SidebarItem
            active={selectedTree(location.pathname) === '5'}
            nodeId="5"
            labelText={t('Exceções Eventos')}
            labelInfo="3,566"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            onClick={() => {
              navigate('exception');
            }}
          />
          <SidebarItem
            active={selectedTree(location.pathname) === '7'}
            nodeId="7"
            labelText={t('Exceções Encargos')}
            labelInfo="3,566"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            onClick={() => {
              navigate('projectExceptions');
            }}
          />
          <SidebarItem
            active={selectedTree(location.pathname) === '6'}
            nodeId="6"
            labelText={t('Regras de Reversão')}
            labelInfo="3,566"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            onClick={() => {
              navigate('reversions');
            }}
          />

          <Typography variant="h2" sx={{ marginY: '1rem', fontWeight: 'bold' }}>
            {t('Consultas')}
          </Typography>
          <SidebarItem
            active={selectedTree(location.pathname) === '8'}
            nodeId="8"
            labelText={t('Na folha sem rateio')}
            labelInfo="2,294"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            onClick={() => {
              navigate('notShared');
            }}
          />
          <SidebarItem
            active={selectedTree(location.pathname) === '9'}
            nodeId="9"
            labelText={t('Eventos por Colaborador')}
            labelInfo="2,294"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            white-space="nowrap"
            onClick={() => {
              navigate('employeeEvents');
            }}
          />

          <Typography variant="h2" sx={{ marginY: '1rem', fontWeight: 'bold' }}>
            {t('Configurações')}
          </Typography>
          <SidebarItem
            active={selectedTree(location.pathname) === '3'}
            nodeId="3"
            labelText={t('Geral')}
            labelInfo="2,294"
            color="#e3742f"
            bgColor="#fcefe3"
            badgeColor="warning"
            onClick={() => {
              navigate('general');
            }}
          />
        </>
      )}
      {isAdmin && (
        <>
          <Typography variant="h2" sx={{ marginY: '1rem', fontWeight: 'bold' }}>
            {t('Administração')}
          </Typography>
          <SidebarItem
            active={selectedTree(location.pathname) === '4'}
            nodeId="4"
            labelText={t('Permissão do Produto')}
            color="#e3742f"
            bgColor="#fcefe3"
            onClick={() => {
              navigate('permission-by-product');
            }}
          />
        </>
      )}
    </TreeView>
  );
}
