import { Typography, IconButton, Tooltip, styled, tooltipClasses } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SyncIcon from '@mui/icons-material/Sync';
import DownloadIcon from '@/icons/download.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import formatDateMonthYear, { formatDate } from '@helpers/date';
import { usePeriodsQuery } from '@/app/services/periodApi';
import { Period } from '@app/models/period';

import { useGenerateSignedLinkQuery } from '@/app/services/processApi';

type CallbackUpdateFunction = (a: Period) => void;
type TranslateFunction = (a: string) => string;
type CallbackUpdateStateFunction = (a: SetStateAction<string | null>) => void;

type ReprocessTooltipProps = {
  children: React.ReactElement<any, any>;
  open: boolean;
  reprocess: CallbackUpdateFunction;
  period: Period;
  t: TranslateFunction;
};

function FilePathLink(params: { period: string }) {
  const { period } = params;
  const { data: file } = useGenerateSignedLinkQuery(period);
  return (
    <div>
      {file?.filePath && (
        <div
          style={{
            width: '135px',
            textAlign: 'start',
          }}
        >
          <a
            href={file?.filePath}
            style={{
              textTransform: 'none',
              textDecoration: 'none',
              display: 'flex',
              margin: '8px',
              marginLeft: '4px',
            }}
          >
            <img src={DownloadIcon} alt="Icone de Download" />
            <Typography sx={{ fontSize: '13px', color: '#666666', marginLeft: '8px' }}>Download</Typography>
          </a>
        </div>
      )}
    </div>
  );
}

const ReprocessTooltip = styled(({ children, open, reprocess, period, t }: ReprocessTooltipProps) => (
  <Tooltip
    arrow
    PopperProps={{
      disablePortal: true,
    }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: '#FFFFFF',

          border: '1px solid #D9D9D9',
          '& .MuiTooltip-arrow': {
            color: '#FFFFFF',
            '&:before': {
              border: '1px solid #D9D9D9',
            },
          },
        },
      },
    }}
    open={open}
    placement="left"
    title={
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          role="presentation"
          style={{
            width: '125px',
            textAlign: 'start',
            cursor: 'pointer',
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
          }}
          onClick={() => reprocess(period)}
          onKeyDown={() => reprocess(period)}
        >
          <SyncIcon
            style={{
              width: '16px',
              height: '16px',
              color: 'black',
            }}
          />
          <Typography
            sx={{
              fontSize: '13px',
              color: '#666666',
              marginLeft: '8px',
            }}
          >
            {t('Reprocessar')}
          </Typography>
        </div>
        {open && <FilePathLink period={period.date} />}
      </div>
    }
  >
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    padding: '1px',
  },
}));

const columns = (
  t: TranslateFunction,
  language: string,
  openTooltip: string | null,
  setOpenTooltip: CallbackUpdateStateFunction,
  reprocess: CallbackUpdateFunction,
) =>
  [
    {
      field: 'date',
      headerName: t('Período'),
      width: 150,
      valueFormatter: ({ value }) => formatDateMonthYear(value, language),
      sortable: false,
    },
    {
      field: 'processingDate',
      headerName: t('Data de processamento'),
      width: 180,
      sortable: false,
      valueFormatter: ({ value }) => formatDate(value, language),
    },
    {
      field: 'processedBy',
      headerName: t('Processado por'),
      flex: 1,
      sortable: false,
      valueFormatter: ({ value }) => value,
    },
    {
      field: 'directSharingProcessed',
      headerName: t('Direto'),
      width: 90,
      sortable: false,
      valueFormatter: ({ value }) => (value ? t('Sim') : t('Não')),
    },
    {
      field: 'indirectSharingProcessed',
      headerName: t('Indireto'),
      width: 100,
      sortable: false,
      valueFormatter: ({ value }) => (value ? t('Sim') : t('Não')),
    },
    {
      field: 'directSharingSynchronized',
      headerName: t('Sincronizado'),
      width: 120,
      sortable: false,
      valueFormatter: ({ value }) => (value ? t('Sim') : t('Não')),
    },
    {
      field: 'id',
      headerName: t('Ações'),
      sortable: false,
      width: 80,
      renderCell: (params: GridRenderCellParams) => (
        <ReprocessTooltip t={t} open={openTooltip === params.id} reprocess={reprocess} period={params.row as Period}>
          <IconButton
            onClick={() => {
              if (openTooltip) {
                setOpenTooltip(null);
              } else {
                setOpenTooltip(params.id as string);
              }
            }}
          >
            <MoreVertIcon className="action" />
          </IconButton>
        </ReprocessTooltip>
      ),
    },
  ] as GridColDef[];

type RequestGridParams = {
  pageOptions: number[];
  setSelectedPeriod: Dispatch<SetStateAction<Period | null>>;
  setOpenNewProcessing: Dispatch<SetStateAction<boolean>>;
};

function PeriodHistory(params: RequestGridParams) {
  const [openTooltip, setOpenTooltip] = useState<string | null>(null);
  const { i18n, t } = useTranslation('processing');
  const { pageOptions, setSelectedPeriod, setOpenNewProcessing } = params;

  const localizedTextsMap = {
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ${t('linhas selecionadas')}`
        : `${count.toLocaleString()} ${t('linha selecionada')}`,
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) =>
        `${from} - ${to} ${t('de')} ${count}`,
    },
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { data, isSuccess, isLoading } = usePeriodsQuery({
    page: paginationModel.page,
    size: paginationModel.pageSize,
    timestamp: 0,
  });

  const reprocess = async (period: Period) => {
    setSelectedPeriod(period);
    setOpenNewProcessing(true);
  };

  const myCustomNoRows = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '360px',
        alignItems: 'center',
      }}
    >
      <Typography>{t('Nenhum resultado encontrado.')}</Typography>
    </div>
  );

  return (
    <div
      style={{
        height: 'calc(100vh - 360px)',
        marginTop: '16px',
      }}
    >
      <div style={{ margin: '30px 0px 10px 0px', color: '#AAAAAA' }}>{t('Histórico')}</div>
      {isSuccess && (
        <DataGrid
          slots={{ noRowsOverlay: myCustomNoRows }}
          disableColumnMenu
          disableColumnFilter
          sx={{
            border: 'none',
            '& .MuiCheckbox-root.Mui-checked': {
              color: '#ff7b00',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
          rowHeight={40}
          columnHeaderHeight={40}
          localeText={localizedTextsMap}
          rows={data.content.map((c) => ({ ...c, id: c.date }))}
          columns={columns(t, i18n.language, openTooltip, setOpenTooltip, reprocess)}
          pageSizeOptions={pageOptions}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          loading={isLoading}
        />
      )}
    </div>
  );
}

export default PeriodHistory;
