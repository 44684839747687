import FlexBetween from '@components/common/FlexBetween';
import getInitials from '@helpers/name';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

function Navbar() {
  const { i18n, t } = useTranslation('navbar');
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const settings = ['Name', 'Email', 'LB', 'Português', 'English', 'LB', t('Sair')]; // add LB to add a line break between menus

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (option: string) => {
    if (option === t('Sair')) {
      navigate('/');
      keycloak.logout();
    } else if (option === 'Português') {
      i18n.changeLanguage('pt');
    } else if (option === 'English') {
      i18n.changeLanguage('en');
    }
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{ position: 'static', background: '#FAFAFA', boxShadow: 'none' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <FlexBetween>
            <Link href="/">
              <img src="/logo_navbar_contabilizacao.svg" alt="CESAR" loading="lazy" />
            </Link>
          </FlexBetween>
        </FlexBetween>
        {/* RIGHT SIDE */}
        <FlexBetween>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar sx={{ bgcolor: '#E87000' }} aria-label="recipe">
                {getInitials(keycloak?.tokenParsed?.name)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => {
              if (setting === 'LB') {
                return <hr key={uuid()} color="#eeeeee" />;
              }
              if (setting === 'Name') {
                return (
                  <Typography key={setting} sx={{ color: '#888888', fontSize: '13px', padding: '6px 16px' }}>
                    {keycloak?.idTokenParsed?.name}
                  </Typography>
                );
              }
              if (setting === 'Email') {
                return (
                  <Typography key={setting} sx={{ color: '#888888', fontSize: '13px', padding: '6px 16px' }}>
                    {keycloak?.idTokenParsed?.email}
                  </Typography>
                );
              }

              if (setting === 'Português') {
                return (
                  <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                    <Typography sx={{ fontSize: '13px', color: i18n.language === 'pt' ? '#F97C06' : '#4B4B4B' }}>
                      {setting}
                    </Typography>
                  </MenuItem>
                );
              }
              if (setting === 'English') {
                return (
                  <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                    <Typography sx={{ fontSize: '13px', color: i18n.language === 'en' ? '#F97C06' : '#4B4B4B' }}>
                      {setting}
                    </Typography>
                  </MenuItem>
                );
              }

              return (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                  <Typography sx={{ fontSize: '13px' }}>{setting}</Typography>
                </MenuItem>
              );
            })}
          </Menu>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
