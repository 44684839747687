import api from '@services/api';

import { AuxiliaryConfig } from '@app/models/auxiliaryConfig';

export const expenseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    config: builder.query<AuxiliaryConfig, void>({
      query: () => ({
        url: encodeURI(`/salary/config`),
      }),
    }),
    updateConfig: builder.mutation<AuxiliaryConfig, AuxiliaryConfig>({
      query: (config) => ({
        url: `/salary/config/createOrUpdate`,
        method: 'POST',
        body: config,
      }),
    }),
  }),
});

export const { useConfigQuery, useUpdateConfigMutation } = expenseApi;
