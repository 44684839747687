import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigQuery, useUpdateConfigMutation } from '@/app/services/auxiliaryConfiguration';
import { toast } from 'react-toastify';
import { Button, InputBase, Paper, Card } from '@mui/material';

function General() {
  const { t } = useTranslation('general');
  const [application, setApplication] = useState<string>();
  const [protocolRequestType, setProtocolRequestType] = useState<string>();
  const [protocolSendType, setProtocolSendType] = useState<string>();
  const { data: config } = useConfigQuery();
  const [updateConfig] = useUpdateConfigMutation();

  useEffect(() => {
    setApplication(config && config.application);
    setProtocolRequestType(config && config.protocolRequestType);
    setProtocolSendType(config && config.protocolSendType);
  }, [config]);

  const save = async () => {
    await updateConfig({ application, protocolRequestType, protocolSendType });

    toast.success(
      t('Configurações atualizadas!', {
        position: toast.POSITION.TOP_RIGHT,
      }),
    );
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginRight: '15px',
      }}
    >
      <div style={{ margin: '26px 0px 4px 0px', fontWeight: 'bold', fontSize: '18px' }}>{t('Configurações')}</div>
      <div style={{ marginBottom: '24px', fontSize: '13px', color: '#888888' }}>
        {t('Configure o nome da aplicação e os tipos de protocolo')}
      </div>
      <Card sx={{ padding: '30px', width: '100%', display: 'flex', flex: 1 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '24px',
            }}
          >
            <div>
              <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '4px' }}>{t('Aplicação')}</div>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 250,
                  height: 32,
                  marginRight: '30px',
                }}
              >
                <InputBase
                  size="small"
                  sx={{
                    fontSize: '13px',
                    ml: 1,
                    flex: 1,
                  }}
                  value={application}
                  onChange={(e) => setApplication(e.target.value)}
                />
              </Paper>
            </div>
            <div>
              <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '4px' }}>
                {t('Tipo de Protocolo de Requisição')}
              </div>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '30px',
                  width: 250,
                  height: 32,
                }}
              >
                <InputBase
                  size="small"
                  sx={{
                    fontSize: '13px',
                    ml: 1,
                    flex: 1,
                  }}
                  value={protocolRequestType}
                  onChange={(e) => setProtocolRequestType(e.target.value)}
                />
              </Paper>
            </div>
            <div>
              <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '4px' }}>
                {t('Tipo de Protocolo de Envio')}
              </div>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: 250,
                  height: 32,
                }}
              >
                <InputBase
                  size="small"
                  sx={{
                    fontSize: '13px',
                    ml: 1,
                    flex: 1,
                  }}
                  value={protocolSendType}
                  onChange={(e) => setProtocolSendType(e.target.value)}
                />
              </Paper>
            </div>
          </div>
          <div>
            <Button onClick={() => save()} variant="primary">
              {t('Salvar')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
export default General;
