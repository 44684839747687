import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Drawer } from '@mui/material';

type CallbackFunction = (v: boolean) => void;
type LateralDrawerProps = {
  open: boolean;
  setOpen: CallbackFunction;
  position: 'right' | 'left' | 'top' | 'bottom';
  children: ReactJSXElement;
  width?: string;
  onClose?: () => void;
};

function LateralDrawer(props: LateralDrawerProps) {
  const { open, setOpen, position, children, width = '480px', onClose = () => {} } = props;
  return (
    <Drawer
      ModalProps={{
        onBackdropClick: () => {
          setOpen(false);
          onClose();
        },
      }}
      open={open}
      anchor={position}
    >
      <Box
        sx={{
          width,
          display: 'flex',
          flexDirection: 'column',
          padding: 3,
          height: '100vh',
          flexGrow: 1,
          backgroundColor: 'primary.main',
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
}

export default LateralDrawer;
