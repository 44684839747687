import api from '@services/api';
import ExpenseException, {
  CreateExpenseException,
  ExpenseExceptionComplete,
  UpdateExpenseException,
} from '../models/expenseException';
import { PaginationResponse } from '../models/pagination';

type GetAllExpenses = {
  searchTerm?: string;
  page: number;
  size: number;
  sortKey?: string;
  sortDirection?: string;
};

type ByCodeQuery = {
  projectCode: string;
  destiniCode: string;
};

type CopyQuery = {
  projectCode: string;
  destiniCode: string;
  newProjectCode: string;
  newDestiniCode: string;
};

type BatchQuery = {
  codes: string[];
};

export const projectTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET ALL
    getExpenseException: builder.query<PaginationResponse<ExpenseException>, GetAllExpenses>({
      query: (input: GetAllExpenses) => ({
        url: encodeURI(
          `/salary/project-expense-exception/search?page=${input.page}&size=${input.size}${
            input.searchTerm ? `&searchTerm=${input.searchTerm}` : ''
          }${input.sortKey ? `&sortKey=${input.sortKey}` : ''}${
            input.sortDirection ? `&sortDirection=${input.sortDirection}` : ''
          }`,
        ),
        method: 'GET',
      }),
      providesTags: ['ExpenseException', 'Expense'],
    }),

    // GET
    getExpenseExceptionByCode: builder.query<Array<ExpenseExceptionComplete>, ByCodeQuery>({
      query: (input: ByCodeQuery) => ({
        url: encodeURI(
          `/salary/project-expense-exception/findAllByProjectCodeAndDestiniCode?projectCode=${input.projectCode}&destiniCode=${input.destiniCode}`,
        ),
        method: 'GET',
      }),
      providesTags: ['ExpenseException', 'Expense'],
    }),
    // UPDATE
    updateExpenseExceptions: builder.mutation<ExpenseException, UpdateExpenseException>({
      query: (expenseException: CreateExpenseException) => ({
        url: '/salary/project-expense-exception/updateInBatch',
        method: 'PUT',
        body: expenseException,
      }),
      invalidatesTags: ['ExpenseException', 'Expense'],
    }),

    // CREATE
    createExpenseException: builder.mutation<ExpenseException, CreateExpenseException>({
      query: (expenseException: CreateExpenseException) => ({
        url: '/salary/project-expense-exception/create',
        method: 'POST',
        body: expenseException,
      }),
      invalidatesTags: ['ExpenseException', 'Expense'],
    }),

    // CREATE
    createBatchExpenseException: builder.mutation<void, BatchQuery>({
      query: (expenseException: BatchQuery) => ({
        url: '/salary/project-expense-exception/createInBatch',
        method: 'POST',
        body: expenseException,
      }),
      invalidatesTags: ['ExpenseException', 'Expense'],
    }),

    // COPY
    copyExpenseException: builder.mutation<ExpenseException, CopyQuery>({
      query: (copy: CopyQuery) => ({
        url: '/salary/project-expense-exception/copy',
        method: 'POST',
        body: copy,
      }),
      invalidatesTags: ['ExpenseException', 'Expense'],
    }),

    // DELETE
    deleteExpenseException: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/salary/project-expense-exception/delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ExpenseException', 'Expense'],
    }),

    // DELETE
    deleteAllExpenseException: builder.mutation<void, ByCodeQuery>({
      query: (input) => ({
        url: `/salary/project-expense-exception/deleteAll?projectCode=${input.projectCode}&destiniCode=${input.destiniCode}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ExpenseException', 'Expense'],
    }),

    // DELETE
    deleteBatchExpenseException: builder.mutation<void, BatchQuery>({
      query: (input: BatchQuery) => ({
        url: `/salary/project-expense-exception/deleteInBatch`,
        method: 'DELETE',
        body: input,
      }),
      invalidatesTags: ['ExpenseException', 'Expense'],
    }),
  }),
});

export const {
  useGetExpenseExceptionQuery,
  useGetExpenseExceptionByCodeQuery,
  useUpdateExpenseExceptionsMutation,
  useCreateExpenseExceptionMutation,
  useCreateBatchExpenseExceptionMutation,
  useCopyExpenseExceptionMutation,
  useDeleteExpenseExceptionMutation,
  useDeleteAllExpenseExceptionMutation,
  useDeleteBatchExpenseExceptionMutation,
} = projectTypeApi;
