import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import ExpenseException from '@/app/models/expenseException';
import { TablePagination, Typography } from '@mui/material';
import ActionMenu, { ItemAction } from '../common/ActionMenu';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelOrConfirmDialog from '../common/dialogs/CancelOrConfirmDialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';
import { useDeleteAllExpenseExceptionMutation } from '@/app/services/expenseExceptionApi';
import { toast } from 'react-toastify';

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  fontSize: 13,
  fontWeight: 'bold',
  paddingBottom: 1,
}));

const RowTableCell = styled(TableCell)({
  fontSize: 13,
});

type ExceptionTableProps = {
  rows: Array<ExpenseException>;
  page: number;
  setPage: (n: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (n: number) => void;
  count: number;
  setShowCreateModal: (b: boolean) => void;
  setShowCopyModal: (b: boolean) => void;
  setEditProject: (p: { name: string; code: string; payingName: string; payingCode: string }) => void;
  setCopyProject: (p: { destiniCode: string; code: string; exceptions: number[] }) => void;
};

function ExceptionTable(props: ExceptionTableProps) {
  const {
    rows,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    setShowCreateModal,
    setEditProject,
    setCopyProject,
    setShowCopyModal,
  } = props;
  const { t } = useTranslation('exceptions');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null as unknown as { destiniCode: string; projectCode: string });

  const [deleteAllExceptions] = useDeleteAllExpenseExceptionMutation();

  const handleDeleteException = async () => {
    const response = await deleteAllExceptions(deleteId);
    setShowDeleteDialog(false);
    if ('data' in response) {
      toast.success(
        t('Casos de Exceção excluidos com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <TableContainer>
      <Table size="small" aria-label="Exception Case Table">
        <TableHead>
          <TableRow>
            <HeaderTableCell align="left">{t('Código')}</HeaderTableCell>
            <HeaderTableCell align="left">{t('Nome')}</HeaderTableCell>
            <HeaderTableCell align="left">{t('Casos de Exceção')}</HeaderTableCell>
            <HeaderTableCell align="right">{t('Ações')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <RowTableCell align="left">{row.project.code}</RowTableCell>
              <RowTableCell align="left">{row.project.name}</RowTableCell>
              <RowTableCell align="left">{row.expenses.map((item, i) => `${i !== 0 ? ',' : ''}${item}`)}</RowTableCell>
              <RowTableCell align="right">
                <ActionMenu>
                  <ItemAction
                    text={t('Editar Casos de Exceção')}
                    Icon={EditOutlinedIcon}
                    onClick={async () => {
                      setEditProject({
                        name: row.project.name,
                        code: row.project.code,
                        payingName: row.destini.name,
                        payingCode: row.destini.code,
                      });
                      setShowCreateModal(true);
                    }}
                  />
                  <ItemAction
                    text={t('Copiar Casos de Exceção')}
                    Icon={ContentCopyIcon}
                    onClick={async () => {
                      setCopyProject({
                        destiniCode: row.destini.code,
                        code: row.project.code,
                        exceptions: row.expenses,
                      });
                      setShowCopyModal(true);
                    }}
                  />
                  <ItemAction
                    text={t('Excluir Todos Casos de Exceção')}
                    Icon={DeleteForeverIcon}
                    onClick={async () => {
                      setDeleteId({ destiniCode: row.destini.code, projectCode: row.project.code });
                      setShowDeleteDialog(true);
                    }}
                  />
                </ActionMenu>
              </RowTableCell>
            </TableRow>
          ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: 'center' }} colSpan={8}>
                <Typography variant="subtitle2">{t('Não há projetos com Casos de Exceção adicionados')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        sx={{ width: '100%' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => setPage(p)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseFloat(e.target.value.replace(',', '.')));
          setPage(0);
        }}
        labelDisplayedRows={({ from, to, count: limit }) => `${from}-${to} ${t('de')} ${limit}`}
        labelRowsPerPage={t('itens por página')}
      />
      <CancelOrConfirmDialog
        title={t('Excluir Exceções')}
        open={showDeleteDialog}
        contentText={t('Tem certeza que deseja excluir todas as exceções?')}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
        onConfirm={() => {
          handleDeleteException();
          setDeleteId(null as unknown as { destiniCode: string; projectCode: string });
        }}
        confirmText={t('Confirmar')}
        cancelText={t('Cancelar')}
      />
    </TableContainer>
  );
}

export default ExceptionTable;
