import { Autocomplete, Box, InputAdornment, InputProps, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import searchIcon from '@/icons/search.svg';
import { useProjectsQuery } from '@/app/services/projectApi';
import { Project } from '@/app/models/project';
import { useCopyExpenseExceptionMutation } from '@/app/services/expenseExceptionApi';
import { toast } from 'react-toastify';
import CancelOrConfirmDialog from '../common/dialogs/CancelOrConfirmDialog';

type CopyExceptionDrawerProps = {
  onClose: () => void;
  copyProject: {
    code: string;
    destiniCode: string;
    exceptions: number[];
  };
};

export default function CopyExceptionDrawer(props: CopyExceptionDrawerProps) {
  const { onClose, copyProject } = props;

  const [copyExpenseException] = useCopyExpenseExceptionMutation();

  const { t } = useTranslation('exceptions');

  const [costCenter, setCostCenter] = useState('');
  const [searchCostCenter, setSearchCostCenter] = useState('');

  const [payingCostCenter, setPayingCostCenter] = useState('');
  const [searchPayingCostCenter, setSearchPayingCostCenter] = useState('');
  const [selectedPayingCostCenter, setSelectedPayingCostCenter] = useState(null as unknown as Project);

  const [confirmDialog, setConfirmDialog] = useState(false);

  const [selectedCostCenter, setSelectedCostCenter] = useState(null as unknown as Project);

  const { data: costCenters } = useProjectsQuery({
    bringClose: false,
    searchTerm: searchCostCenter,
    page: 0,
    size: 10,
  });
  const { data: payingCostCenters } = useProjectsQuery({
    bringClose: false,
    searchTerm: searchPayingCostCenter,
    page: 0,
    size: 10,
  });

  const handleSubmit = async () => {
    const response = await copyExpenseException({
      projectCode: copyProject.code,
      destiniCode: copyProject.destiniCode,
      newProjectCode: selectedCostCenter.code,
      newDestiniCode: selectedPayingCostCenter.code,
    });
    if ('data' in response) {
      toast.success(
        t('Caso de Exceção copiado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh )',
        maxHeight: 'calc(100vh )',
        flexGrow: 'inherit',
        background: '#FAFAFA',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
        <div style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '24px' }}>
          <Typography variant="h1">{t('Copiar Casos de Exceção')}</Typography>
        </div>

        <div>
          <Typography variant="h3">{t('Projetos')}</Typography>
          <Typography variant="subtitle1">
            {t('Escolha o Projeto para qual você deseja adicionar Casos de Exceção')}
          </Typography>
          <Autocomplete
            freeSolo
            filterOptions={(option) => option}
            isOptionEqualToValue={(option, value) => option?.code === value?.code}
            style={{ width: '100%', marginRight: '10px', marginTop: '8px' }}
            size="small"
            defaultValue={selectedCostCenter}
            onChange={(event, newValue) => {
              setSelectedCostCenter(newValue as Project);
            }}
            inputValue={costCenter}
            onInputChange={(event, newInputValue) => {
              setCostCenter(newInputValue);
              if (newInputValue.length >= 1) {
                setSearchCostCenter(newInputValue);
              }
            }}
            options={costCenters?.content || []}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : `${option.code} - ${option.name} (${option.branchName})`
            }
            renderOption={(params, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                {`${option.code} - ${option.name} (${option.branchName})`}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& input': {
                    padding: '0px',
                  },
                }}
                size="small"
                style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                ref={params.InputProps.ref}
                InputProps={
                  {
                    ...params.inputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                      </InputAdornment>
                    ),
                    style: {
                      paddingRight: '10px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      paddingLeft: '10px',
                      fontSize: '13px',
                    },
                  } as InputProps
                }
                autoFocus
                placeholder={t('Digite nome ou código do centro de custo') as string}
              />
            )}
          />
        </div>

        <div style={{ marginTop: '24px' }}>
          <Typography variant="h3">{t('Projeto Pagador')}</Typography>
          <Typography variant="subtitle1">
            {t('Agora informe o projeto que você deseja adicionar como pagador')}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Autocomplete
              freeSolo
              filterOptions={(option) => option}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              style={{ width: '100%', marginTop: '8px' }}
              size="small"
              defaultValue={selectedPayingCostCenter}
              onChange={(event, newValue) => {
                setSelectedPayingCostCenter(newValue as Project);
              }}
              inputValue={payingCostCenter}
              onInputChange={(event, newInputValue) => {
                setPayingCostCenter(newInputValue);
                if (newInputValue.length >= 1) {
                  setSearchPayingCostCenter(newInputValue);
                }
              }}
              options={payingCostCenters?.content || []}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : `${option.code} - ${option.name} (${option.branchName})`
              }
              renderOption={(params, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                  {`${option.code} - ${option.name} (${option.branchName})`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& input': {
                      padding: '0px',
                    },
                  }}
                  size="small"
                  style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                  ref={params.InputProps.ref}
                  InputProps={
                    {
                      ...params.inputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        fontSize: '13px',
                      },
                    } as InputProps
                  }
                  autoFocus
                  placeholder={t('Pesquise por nome ou código do projeto') as string}
                />
              )}
            />
          </div>
          <div style={{ width: '100%', border: '1px solid #CCCCCC', marginTop: '32px' }} />
        </div>
        <div style={{ marginTop: '24px' }}>
          <Typography variant="h3">{t('Exceções')}</Typography>
          <ul>
            {copyProject?.exceptions?.map((item) => (
              <li key={item}>
                <Typography>{item}</Typography>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancelar')}
        </Button>

        <Button
          variant="primary"
          disabled={!selectedCostCenter || !selectedPayingCostCenter}
          onClick={() => {
            setConfirmDialog(true);
          }}
          sx={{ marginLeft: '16px' }}
        >
          {t('Salvar')}
        </Button>
      </div>
      <CancelOrConfirmDialog
        open={confirmDialog}
        title={t('Copiar Exceções')}
        contentText={t('Desja realizar uma cópia das exceções para esse novo cadastro?')}
        onClose={() => {
          setConfirmDialog(false);
        }}
        onConfirm={() => {
          handleSubmit();
          onClose();
        }}
        cancelText={t('Cancelar')}
        confirmText={t('Confirmar')}
      />
    </div>
  );
}
