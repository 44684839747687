import api from '@services/api';
import { PaginationResponse } from '@/app/models/pagination';
import { Branch } from '../models/branch';

/**
 * <p>
 * API endpoints that cover all resource sharing CRUD calls
 */

type QueryParams = {
  searchTerm?: string;
  page: number;
  size: number;
  sortKey?: string;
  sortDirection?: string;
};

export const branchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    branch: builder.query<PaginationResponse<Branch>, QueryParams>({
      query: (queryInput) => ({
        url: encodeURI(
          `/salary/branch/search?searchTer=${queryInput.searchTerm}&page=${queryInput.page}&size=${queryInput.size}&${
            queryInput.searchTerm ? `searchTerm=${queryInput.searchTerm}&` : ''
          }${queryInput.sortKey ? `&sortKey=${queryInput.sortKey}` : ''}${
            queryInput.sortDirection ? `&sortDirection=${queryInput.sortDirection}` : ''
          }`,
        ),
      }),
      providesTags: ['Branch'],
    }),
  }),
});

export const { useBranchQuery } = branchApi;
