import api from '@services/api';
import { Project } from '../models/project';
import { PaginationResponse } from '../models/pagination';

/**
 * <p>
 * API endpoints that cover all resource project related CRUD calls
 */

interface ProjectSeatch {
  bringClose: boolean;
  searchTerm: string;
  page: number;
  size: number;
}

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    projects: builder.query<PaginationResponse<Project>, ProjectSeatch>({
      query: (input) => ({
        url: encodeURI(
          `/salary/project/search?bringClose=${input.bringClose}&searchTerm=${input.searchTerm}&page=${input.page}&size=${input.size}&sortKey=name&sortDirection=ASC`,
        ),
        method: 'GET',
      }),
      providesTags: ['Products'],
    }),
  }),
});

export const { useProjectsQuery } = projectApi;
