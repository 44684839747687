import { Period } from '@/app/models/period';
import { useAllPersonByPeriodQuery } from '@/app/services/monthlyExpenseApi';
import PeriodDetails from '@/components/events/PeriodDetails';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function NotShared() {
  const { t } = useTranslation('notShared');
  const [selectedPeriod, setSelectedPeriod] = useState<Period>();

  const { data: personData } = useAllPersonByPeriodQuery(selectedPeriod?.date as string);

  const List = Array.isArray(personData) ? personData : [];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: '15px' }}>
      <PeriodDetails selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
      <div style={{ marginTop: '32px', fontSize: '18px', fontWeight: 'bold' }}>
        {t('on.the.sheet.without.allocation')}
      </div>
      <div style={{ color: '#AAAAAA' }}>
        {t('list.of.collaborators.who.are.in')}
        <strong>{t('payroll')}</strong>
        {t('of.payment.but')}
        <strong>{t('not.included.distribution')}</strong>
        {t('chosen.period')}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}>
        <Box
          sx={{
            display: 'flex',
            fontSize: '13px',
            fontWeight: 'bold',
            cursor: 'pointer',
            padding: '8px 54px 8px 14px',
          }}
        >
          {t('employee.registration')}
        </Box>
      </div>
      <div style={{ display: 'flex', flex: 1, borderBottom: `2px solid #bababa` }} />
      <div style={{ padding: '20px' }}>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          {List.map((item) => (
            <li key={item} style={{ padding: '4px 0' }}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div />
    </div>
  );
}
export default NotShared;
