import { Category } from '@/app/models/category';
import { Expense } from '@/app/models/expense';
import { ExpenseGroup } from '@/app/models/expenseGroup';
import { useExpenseQuery, useUpdateExpenseMutation } from '@/app/services/expenseApi';
import { Box, InputAdornment, InputProps, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import searchIcon from '@/icons/search.svg';

import { ON_CHANGE_DELAY } from '@/app/constants/values';

import { useThrottle } from 'react-use';

import { PaginationResponse } from '@/app/models/pagination';
import Select, { SelectChangeEvent } from '@mui/material/Select';

type TranslateFunction = (a: string) => string;

type CallbackUpdateFunction = (a: string | boolean | ExpenseGroup | Category, b: string, c: string) => void;

interface SearchBarsProps {
  searchTerm: string;
  setSearchTerm: (arg0: string) => void;
}

export function SearchBar(props: SearchBarsProps) {
  const { searchTerm, setSearchTerm } = props;

  const { t } = useTranslation('events');

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '25ch', ml: 0 },
      }}
      noValidate
      autoComplete="off"
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '23px',
        }}
      >
        <div style={{ display: 'flex', width: '70%', margin: 'auto 0' }}>
          <TextField
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ minWidth: '360px', height: '32px', fontSize: '13px' }}
            autoFocus
            placeholder={t('Pesquise pelo número do código') as string}
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={searchIcon} alt={t('ícone de pesquisa') as string} />
                  </InputAdornment>
                ),
                style: {
                  paddingRight: '10px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingLeft: '10px',
                  fontSize: '13px',
                },
              } as InputProps
            }
          />
        </div>
      </div>
      <div />
    </Box>
  );
}

const columns = (
  t: TranslateFunction,
  groups: Array<ExpenseGroup>,
  categories: Array<Category>,
  updateExpense: CallbackUpdateFunction,
) =>
  [
    {
      field: 'code',
      headerName: t('Código'),
      width: 100,
      valueFormatter: ({ value }) => value.name,
      sortable: false,
    },
    {
      field: 'isShareable',
      headerName: t('Rateável'),
      width: 160,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ToggleButtonGroup
            exclusive
            value={params.value}
            onChange={(event: React.MouseEvent<HTMLElement>, isShareable: boolean) => {
              updateExpense(isShareable, params.row.code, 'isShareable');
            }}
          >
            <ToggleButton
              value
              sx={{
                height: '22px',
                fontSize: '13px',
                marginRight: '0px',
                textTransform: 'none',
              }}
            >
              {t('Sim')}
            </ToggleButton>
            <ToggleButton
              value={false}
              sx={{
                marginRight: '16px',
                height: '22px',
                textTransform: 'none',
                fontSize: '13px',
              }}
            >
              {t('Não')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      ),
    },
    {
      field: 'isCredit',
      headerName: t('Lançamento'),
      width: 170,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ToggleButtonGroup
            exclusive
            value={params.value}
            onChange={(event: React.MouseEvent<HTMLElement>, isCredit: boolean) => {
              updateExpense(isCredit, params.row.code, 'isCredit');
            }}
          >
            <ToggleButton
              value={false}
              sx={{
                height: '22px',
                fontSize: '13px',
                marginRight: '0px',
                textTransform: 'none',
              }}
            >
              {t('Débito')}
            </ToggleButton>
            <ToggleButton
              value
              sx={{
                marginRight: '16px',
                height: '22px',
                textTransform: 'none',
                fontSize: '13px',
              }}
            >
              {t('Crédito')}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      ),
    },
    {
      field: 'category',
      headerName: t('Categoria'),
      width: 180,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ToggleButtonGroup
            exclusive
            value={categories.find((c) => c.name === params.value?.name) || {}}
            onChange={(event: React.MouseEvent<HTMLElement>, category: Category) => {
              updateExpense(category, params.row.code, 'category');
            }}
          >
            {categories.map((category) => (
              <ToggleButton
                key={category.id}
                value={category}
                sx={{
                  height: '22px',
                  fontSize: '13px',
                  marginRight: '0px',
                  textTransform: 'none',
                }}
              >
                {t(category.name)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ),
    },
    {
      field: 'expenseGroup',
      headerName: t('Grupo'),
      width: 210,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Select
            value={groups.find((g) => g.name === params.value?.name) || ''}
            size="small"
            label={t('Selecione o grupo')}
            placeholder={t('Selecione o grupo')}
            sx={{ width: '180px', fontSize: '11px' }}
            onChange={(event: SelectChangeEvent<ExpenseGroup>) => {
              updateExpense(event.target.value, params.row.code, 'expenseGroup');
            }}
          >
            {groups.map((group) => (
              <MenuItem
                sx={{
                  fontSize: '13px',
                  fontWeight: group.name === params.value?.name ? 'bold' : 'normal',
                  color: group.name === params.value?.name ? '#F97C06' : '#4B4B4B',
                }}
                key={group.name}
                value={group as any}
              >
                {t(group.name)}
              </MenuItem>
            ))}
          </Select>
        </div>
      ),
    },
    {
      field: 'name',
      headerName: t('Descrição'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <TextField
            value={params.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateExpense(event.target.value, params.row.code, 'name');
            }}
            sx={{ width: '100%' }}
            size="small"
            inputProps={{ style: { fontSize: '11px' } }}
          />
        </div>
      ),
    },
  ] as GridColDef[];

type RequestGridParams = {
  pageOptions: number[];
  groups: ExpenseGroup[];
  categories: Category[];
};

function NotRegisteredEvents(params: RequestGridParams) {
  const { t } = useTranslation('events');
  const { pageOptions, groups, categories } = params;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [lastTimestamp, setLastTimestamp] = useState(0);
  const [expenses, setExpenses] = useState([] as Array<Expense>);

  const [updateExpense] = useUpdateExpenseMutation();

  const [searchTerm, setSearchTerm] = useState('');
  const throttledSearchTerm = useThrottle(searchTerm, ON_CHANGE_DELAY);

  const localizedTextsMap = {
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ${t('linhas selecionadas')}`
        : `${count.toLocaleString()} ${t('linha selecionada')}`,
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) =>
        `${from} - ${to} ${t('de')} ${count}`,
    },
  };

  const { data, isSuccess, isLoading } = useExpenseQuery({
    bringOnlyNew: true,
    page: paginationModel.page,
    size: paginationModel.pageSize,
    timestamp: lastTimestamp,
    searchTerm: throttledSearchTerm,
  });

  const [rowCountState, setRowCountState] = useState(data?.totalElements || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.totalElements !== undefined ? data?.totalElements : prevRowCountState,
    );
  }, [data?.totalElements, setRowCountState]);

  const updateExpenseProperty = (value: string | boolean | ExpenseGroup | Category, code: string, property: string) => {
    const expensesCopy = [...expenses];
    const index = expensesCopy.findIndex((e) => e.code === code);
    if (index !== -1) {
      expensesCopy[index] = { ...expensesCopy[index] };
      Object.assign(expensesCopy[index], { [property]: value });
    }
    setExpenses(expensesCopy);
  };
  const cleanExpenses = (receivedData: PaginationResponse<Expense> | undefined) => {
    const content = (receivedData && [...receivedData.content]) || [];
    for (let i = 0; i < content.length; i += 1) {
      content[i] = { ...content[i] };
    }
    setExpenses(content);
  };

  const updateExpenses = async () => {
    for (let i = 0; i < expenses.length; i += 1) {
      const expense = expenses[i];
      if (
        !(
          !expense.category ||
          !expense.expenseGroup ||
          (expense.isCredit !== true && expense.isCredit !== false) ||
          (expense.isShareable !== true && expense.isShareable !== false)
        )
      ) {
        updateExpense({
          code: expense.code,
          name: expense.name,
          isCredit: expense.isCredit,
          isShareable: expense.isShareable,
          expenseGroupId: expense.expenseGroup?.id,
          categoryId: expense.category?.id,
        });
      }
    }
    toast.success(
      t('Eventos atualizados!', {
        position: toast.POSITION.TOP_RIGHT,
      }),
    );
    setLastTimestamp(lastTimestamp + 1);
  };

  useEffect(() => {
    cleanExpenses(data);
  }, [data]);

  const disabled =
    !expenses.every((e) => {
      const allTrue =
        e.category &&
        e.expenseGroup &&
        (e.isCredit === true || e.isCredit === false) &&
        (e.isShareable === true || e.isShareable === false);
      const allFalse =
        !e.category &&
        !e.expenseGroup &&
        e.isCredit !== true &&
        e.isCredit !== false &&
        e.isShareable !== true &&
        e.isShareable !== false;
      return allTrue || allFalse;
    }) ||
    !expenses.some((e) => {
      const allTrue =
        e.category &&
        e.expenseGroup &&
        (e.isCredit === true || e.isCredit === false) &&
        (e.isShareable === true || e.isShareable === false);
      return allTrue;
    });

  const myCustomNoRows = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '360px',
        alignItems: 'center',
      }}
    >
      <Typography>{t('Nenhum resultado encontrado.')}</Typography>
    </div>
  );

  return (
    <div style={{ height: 'calc(100vh - 420px)', marginTop: '16px' }}>
      <div style={{ marginBottom: '24px' }}>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </div>
      {isSuccess && (
        <DataGrid
          slots={{ noRowsOverlay: myCustomNoRows }}
          disableColumnMenu
          disableColumnFilter
          rowCount={rowCountState}
          sx={{
            border: 'none',
            '& .MuiCheckbox-root.Mui-checked': {
              color: '#ff7b00',
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
          rowHeight={56}
          columnHeaderHeight={40}
          rows={expenses}
          columns={columns(t, groups || [], categories || [], updateExpenseProperty)}
          pageSizeOptions={pageOptions}
          paginationModel={paginationModel}
          localeText={localizedTextsMap}
          onPaginationModelChange={setPaginationModel}
          paginationMode="server"
          loading={isLoading}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="secondary"
          style={{
            marginLeft: '16px',
          }}
          onClick={() => cleanExpenses(data)}
        >
          {t('Limpar')}
        </Button>
        <Button
          onClick={() => updateExpenses()}
          disabled={disabled}
          variant="primary"
          style={{
            marginLeft: '16px',
          }}
        >
          {t('Salvar')}
        </Button>
      </div>
    </div>
  );
}

export default NotRegisteredEvents;
