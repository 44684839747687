import api from '@services/api';

export const notificationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    periodChangeStatus: builder.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['Periods'],
    }),
  }),
});

export const { usePeriodChangeStatusMutation } = notificationsApi;
