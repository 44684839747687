import { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
  }
}

const themeLight: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#fafafa',
    },
    secondary: {
      main: '#f97c06',
    },
    warning: {
      main: '#ffc107',
    },
    error: {
      main: '#ef4747',
    },
    success: {
      main: '#28a745',
    },
    text: {
      primary: '#4b4b4b',
      secondary: '#757575',
    },
  },
  typography: {
    fontSize: 13,
    h1: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '24px',
    },
    h2: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    h3: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: '13px',
      color: '#CCCCCC',
    },
    subtitle2: {
      fontSize: '13px',
      color: '#888888',
      lineHeight: '16px',
    },
    body2: {
      fontSize: '14px',
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 40,
          '&:nth-of-type(odd)': {
            backgroundColor: 'white',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#FAFAFA',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c2c2c2',
            borderWidth: 1,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#979797',
            borderWidth: 1,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#818181',
            borderWidth: 1,
          },
          '&.Mui-disabled': {
            borderColor: '#c2c2c2',
            borderWidth: 1,
            backgroundColor: '#F4F4F4',
          },
          '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#c2c2c2',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            padding: '6px 8px',
            color: '#FFFFFF',
            textTransform: 'none',
            backgroundColor: '#E87305',
            fontWeight: 'bold',
            '&.Mui-disabled': {
              color: '#FFFFFF',
              backgroundColor: '#E8BA90',
              opacity: 0.4,
            },
            '&:hover': {
              backgroundColor: '#CF6604',
            },
            '&:focus': {
              backgroundColor: '#B54E04',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            padding: '6px 8px',
            textTransform: 'none',
            color: '#4b4b4b',
            backgroundColor: '#F5F5F5',
            fontWeight: 'bold',
            '&.Mui-disabled': {
              opacity: 0.4,
              color: '#4b4b4b',
              backgroundColor: '#F2F2F2',
            },
            '&:hover': {
              backgroundColor: '#DBDBDB',
            },
            '&:focus': {
              backgroundColor: '#C2C2C2',
            },
          },
        },
        {
          props: { variant: 'tertiary' },
          style: {
            padding: '6px 8px',
            textTransform: 'none',
            color: '#F97C06',
            fontWeight: 'bold',
            '&.Mui-disabled': {
              opacity: 0.4,
              color: '#F97C06',
            },
            '&:hover': {},
            '&:focus': {},
          },
        },
      ],
    },
  },
};

function themeSettings(mode: string): ThemeOptions {
  if (mode === 'light') {
    return themeLight;
  }

  return themeLight;
}

export default themeSettings;
