import { Autocomplete, Box, InputAdornment, InputProps, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SearchField from '../common/SearchField';
import { useEffect, useState } from 'react';
import searchIcon from '@/icons/search.svg';
import { useProjectsQuery } from '@/app/services/projectApi';
import { Project } from '@/app/models/project';
import { useExpenseQuery } from '@/app/services/expenseApi';
import { Expense } from '@/app/models/expense';
import CreateExceptionsTable from '../tables/CreateExceptionsTable';

import { NewExpenseException } from '@/app/models/expenseException';
import {
  useCreateExpenseExceptionMutation,
  useDeleteExpenseExceptionMutation,
  useGetExpenseExceptionByCodeQuery,
  useUpdateExpenseExceptionsMutation,
} from '@/app/services/expenseExceptionApi';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import CancelOrConfirmDialog from '../common/dialogs/CancelOrConfirmDialog';

type CreateExceptionDrawerProps = {
  onClose: () => void;
  editProject: {
    name: string;
    code: string;
    payingName: string;
    payingCode: string;
  };
};

export default function CreateExceptionDrawer(props: CreateExceptionDrawerProps) {
  const { onClose, editProject } = props;

  const [createExpenseException] = useCreateExpenseExceptionMutation();
  const [deleteExpenseException] = useDeleteExpenseExceptionMutation();
  const [editExpenseException] = useUpdateExpenseExceptionsMutation();

  const { t } = useTranslation('exceptions');

  // const [newExceptions, setNewExceptions] = useState([] as NewExpenseException[])
  const [costCenter, setCostCenter] = useState('');
  const [searchCostCenter, setSearchCostCenter] = useState('');

  const [payingCostCenter, setPayingCostCenter] = useState('');
  const [searchPayingCostCenter, setSearchPayingCostCenter] = useState('');
  const [selectedPayingCostCenter, setSelectedPayingCostCenter] = useState(null as unknown as Project);
  const [batchEdit, setBatchEdit] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [expense, setExpense] = useState('');
  const [searchExpense, setSearchExpense] = useState('');
  const [selectedExpense, setSelectedExpense] = useState(null as unknown as Expense);
  const { data: foundProject } = useProjectsQuery(
    { bringClose: true, searchTerm: editProject?.code || '', page: 0, size: 10 },
    { skip: !editProject },
  );
  const { data: foundPayingProject } = useProjectsQuery(
    { bringClose: true, searchTerm: editProject?.payingCode || '', page: 0, size: 10 },
    { skip: !editProject },
  );
  const [selectedCostCenter, setSelectedCostCenter] = useState(null as unknown as Project);

  const { data: costCenters } = useProjectsQuery({
    bringClose: false,
    searchTerm: searchCostCenter,
    page: 0,
    size: 10,
  });
  const { data: payingCostCenters } = useProjectsQuery({
    bringClose: false,
    searchTerm: searchPayingCostCenter,
    page: 0,
    size: 10,
  });
  const { data: expenses } = useExpenseQuery({ searchTerm: searchExpense, bringOnlyNew: false, page: 0, size: 10 });
  const { data: projectExpenseExceptions } = useGetExpenseExceptionByCodeQuery(
    { projectCode: selectedCostCenter?.code, destiniCode: selectedPayingCostCenter?.code },
    { skip: !selectedCostCenter },
  );
  const { data: foundExpenseExceptions } = useGetExpenseExceptionByCodeQuery(
    { projectCode: foundProject?.content[0].code || '', destiniCode: foundPayingProject?.content[0].code || '' },
    { skip: !foundProject || !foundPayingProject },
  );

  const handleSubmit = async () => {
    if (selectedCostCenter && selectedExpense && selectedPayingCostCenter) {
      const response = await createExpenseException({
        code: selectedExpense.code,
        projectCode: selectedCostCenter.code,
        destiniCode: selectedPayingCostCenter.code,
      });
      if ('data' in response) {
        toast.success(
          t('Caso de Exceção adicionado na lista com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
    }
    if (foundPayingProject && foundProject && editProject && selectedExpense) {
      const response = await createExpenseException({
        code: selectedExpense.code,
        projectCode: editProject.code,
        destiniCode: editProject.payingCode,
      });
      if ('data' in response) {
        toast.success(
          t('Caso de Exceção adicionado na lista com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
          }),
        );
      }
    }
  };

  const handleDelete = async (id: number) => {
    const response = await deleteExpenseException(id);
    if ('data' in response) {
      toast.success(
        t('Caso de Exceção excluido da lista com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  const handleBatchEdit = async () => {
    const response = await editExpenseException({
      projectCode: editProject.code,
      destiniCode: selectedPayingCostCenter.code,
    });
    if ('data' in response) {
      toast.success(
        t('Caso de Exceção editado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT,
        }),
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh )',
        maxHeight: 'calc(100vh )',
        flexGrow: 'inherit',
        background: '#FAFAFA',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
        <div style={{ color: '#4B4B4B', fontSize: '18px', fontWeight: 'bold', marginBottom: '24px' }}>
          <Typography variant="h1">{t('Casos de Exceção')}</Typography>
        </div>
        {foundProject?.content ? (
          <Box>
            <Typography variant="h3">{t('Projetos')}</Typography>
            <Paper sx={{ padding: 3, display: 'flex' }}>
              <Box sx={{ marginRight: 8 }}>
                <Typography variant="subtitle2">{t('Código')}</Typography>
                <Typography variant="h3">{foundProject?.content[0].code}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">{t('Nome')}</Typography>
                <Typography variant="h3">{foundProject?.content[0].name}</Typography>
              </Box>
            </Paper>
          </Box>
        ) : (
          <div>
            <Typography variant="h3">{t('Projetos')}</Typography>
            <Typography variant="subtitle1">
              {t('Escolha o Projeto para qual você deseja adicionar Casos de Exceção')}
            </Typography>
            <Autocomplete
              freeSolo
              filterOptions={(option) => option}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              style={{ width: '100%', marginRight: '10px', marginTop: '8px' }}
              size="small"
              defaultValue={selectedCostCenter}
              onChange={(event, newValue) => {
                setSelectedCostCenter(newValue as Project);
              }}
              inputValue={costCenter}
              onInputChange={(event, newInputValue) => {
                setCostCenter(newInputValue);
                if (newInputValue.length >= 1) {
                  setSearchCostCenter(newInputValue);
                }
              }}
              options={costCenters?.content || []}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : `${option.code} - ${option.name} (${option.branchName})`
              }
              renderOption={(params, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                  {`${option.code} - ${option.name} (${option.branchName})`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& input': {
                      padding: '0px',
                    },
                  }}
                  size="small"
                  style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                  ref={params.InputProps.ref}
                  InputProps={
                    {
                      ...params.inputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        fontSize: '13px',
                      },
                    } as InputProps
                  }
                  autoFocus
                  placeholder={t('Digite nome ou código do centro de custo') as string}
                />
              )}
            />
          </div>
        )}
        {foundPayingProject?.content && !batchEdit ? (
          <Box sx={{ marginTop: 3 }}>
            <Typography variant="h3">{t('Projeto Pagador')}</Typography>
            <Paper sx={{ padding: 3, display: 'flex' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
                <Box sx={{ display: 'flex', flex: 1 }}>
                  <Box sx={{ marginRight: 8 }}>
                    <Typography variant="subtitle2">{t('Código')}</Typography>
                    <Typography variant="h3">{foundPayingProject?.content[0].code}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">{t('Nome')}</Typography>
                    <Typography variant="h3">{foundPayingProject?.content[0].name}</Typography>
                  </Box>
                </Box>
                <Button
                  onClick={() => {
                    setBatchEdit(true);
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifySelf: 'end',
                    marginLeft: '',
                  }}
                >
                  <EditIcon sx={{ color: '#4B4B4B', fontSize: 32 }} />
                </Button>
              </Box>
            </Paper>
          </Box>
        ) : (
          <div style={{ marginTop: '24px' }}>
            <Typography variant="h3">{t('Projeto Pagador')}</Typography>
            <Typography variant="subtitle1">
              {t('Agora informe o projeto que você deseja adicionar como pagador')}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <Autocomplete
                freeSolo
                filterOptions={(option) => option}
                isOptionEqualToValue={(option, value) => option?.code === value?.code}
                style={{ width: '100%', marginTop: '8px' }}
                size="small"
                defaultValue={selectedPayingCostCenter}
                onChange={(event, newValue) => {
                  setSelectedPayingCostCenter(newValue as Project);
                }}
                inputValue={payingCostCenter}
                onInputChange={(event, newInputValue) => {
                  setPayingCostCenter(newInputValue);
                  if (newInputValue.length >= 1) {
                    setSearchPayingCostCenter(newInputValue);
                  }
                }}
                options={payingCostCenters?.content || []}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : `${option.code} - ${option.name} (${option.branchName})`
                }
                renderOption={(params, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                    {`${option.code} - ${option.name} (${option.branchName})`}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      '& input': {
                        padding: '0px',
                      },
                    }}
                    size="small"
                    style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                    ref={params.InputProps.ref}
                    InputProps={
                      {
                        ...params.inputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                          </InputAdornment>
                        ),
                        style: {
                          paddingRight: '10px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          paddingLeft: '10px',
                          fontSize: '13px',
                        },
                      } as InputProps
                    }
                    autoFocus
                    placeholder={t('Pesquise por nome ou código do projeto') as string}
                  />
                )}
              />
            </div>

            <div style={{ width: '100%', border: '1px solid #CCCCCC', marginTop: '32px' }} />
          </div>
        )}
        <div style={{ marginTop: '32px', marginBottom: '19px' }}>
          <Typography variant="h3">{t('Eventos')}</Typography>
          <Typography variant="subtitle1">{t('Indique o Evento que você deseja adicionar')}</Typography>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            <Autocomplete
              freeSolo
              filterOptions={(option) => option}
              isOptionEqualToValue={(option, value) => option?.code === value?.code}
              style={{ width: '100%', marginRight: '10px', marginTop: '8px' }}
              size="small"
              defaultValue={selectedExpense}
              onChange={(event, newValue) => {
                setSelectedExpense(newValue as Expense);
              }}
              inputValue={expense}
              onInputChange={(event, newInputValue) => {
                setExpense(newInputValue);
                if (newInputValue.length >= 1) {
                  setSearchExpense(newInputValue);
                }
              }}
              options={expenses?.content || []}
              getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.code}`)}
              renderOption={(params, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Box component="li" sx={{ fontSize: '13px', '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
                  {`${option.code}`}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  sx={{
                    '& input': {
                      padding: '0px',
                    },
                  }}
                  size="small"
                  style={{ width: 'inherit', height: '32px', fontSize: '13px' }}
                  ref={params.InputProps.ref}
                  InputProps={
                    {
                      ...params.inputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} alt={t('icone de pesquisa') as string} />
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: '10px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '10px',
                        fontSize: '13px',
                      },
                    } as InputProps
                  }
                  autoFocus
                  placeholder={t('Pesquisar pelo código do evento') as string}
                />
              )}
            />
            <Button
              sx={{ marginLeft: '24px', marginRight: '16px' }}
              variant="tertiary"
              onClick={() => {
                handleSubmit();
              }}
            >
              {t('+Adicionar')}
            </Button>
          </div>
          <div style={{ display: 'flex', marginTop: '24px' }}>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Código')}
              </Typography>
              <Typography variant="h3">{selectedExpense ? selectedExpense.code : '---'}</Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Rateável')}
              </Typography>
              <Typography variant="h3">
                {(selectedExpense && (selectedExpense.isShareable ? t('Sim') : t('Não'))) || '---'}
              </Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Lançamento')}
              </Typography>
              <Typography variant="h3">
                {(selectedExpense && (selectedExpense.isCredit ? t('Crédito') : t('Débito'))) || '---'}
              </Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Categoria')}
              </Typography>
              <Typography variant="h3">{selectedExpense ? selectedExpense.category?.name : '---'}</Typography>
            </div>
            <div style={{ marginRight: '40px' }}>
              <Typography variant="subtitle2" sx={{ color: '#757575' }}>
                {t('Grupo')}
              </Typography>
              <Typography variant="h3">{selectedExpense ? selectedExpense.expenseGroup?.name : '---'}</Typography>
            </div>
          </div>
        </div>

        <CreateExceptionsTable
          rows={foundProject && foundPayingProject ? foundExpenseExceptions || [] : projectExpenseExceptions || []}
          onDelete={handleDelete}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <Button
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {t('Cancelar')}
        </Button>
        {batchEdit && selectedPayingCostCenter && (
          <Button
            variant="primary"
            onClick={() => {
              setConfirmDialog(true);
            }}
            sx={{ marginLeft: '16px' }}
          >
            {t('Salvar')}
          </Button>
        )}
      </div>
      <CancelOrConfirmDialog
        open={confirmDialog}
        title={t('Editar Exceção')}
        contentText={t(
          'Todas as exceções vão ser modificadas para o novo projeto pagador, tem certeza que deseja editar?',
        )}
        onClose={() => {
          setConfirmDialog(false);
        }}
        onConfirm={() => {
          handleBatchEdit();
          onClose();
        }}
        cancelText={t('Cancelar')}
        confirmText={t('Confirmar')}
      />
    </div>
  );
}
