import { Period } from '@app/models/period';
import api from '@services/api';
import { EmployeeEventsRequest, EmployeeEventsResponse } from '../models/Shared';

/**
 * <p>
 * API endpoints that cover all resource period related CRUD calls
 */
export const periodApi = api.injectEndpoints({
  endpoints: (builder) => ({
    synchronizeMonthlyExpense: builder.mutation<Period, string>({
      query: (period) => ({
        url: `/salary/monthly-expense/synchronize?period=${period}`,
        method: 'POST',
      }),
    }),
    allPersonByPeriod: builder.query<string[], string>({
      query: (period) => ({
        url: `/salary/monthly-expense/allPersonWithoutSharingByPeriod?period=${period}`,
        method: 'GET',
      }),
      providesTags: ['allPersonByPeriod'],
    }),

    allPersonExpense: builder.query<EmployeeEventsResponse[], EmployeeEventsRequest>({
      query: (request) => ({
        url: `/salary/monthly-expense/allPersonExpense?period=${request.period}&badge=${request.badge}`,
        method: 'GET',
      }),
      providesTags: ['allPersonExpense'],
    }),
  }),
});

export const { useSynchronizeMonthlyExpenseMutation, useAllPersonByPeriodQuery, useAllPersonExpenseQuery } = periodApi;
